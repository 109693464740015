import React from 'react'
import { Item, Token, Flex, Icon } from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useDeleteMergeConnection } from '@src/api/integrations'

interface DisconnectMergeIntegrationItemProps {
  title: string
  integrationId: string
  onSuccess?: () => void
}

export const DisconnectMergeIntegrationItem = ({
  title,
  integrationId,
  onSuccess,
}: DisconnectMergeIntegrationItemProps) => {
  const confirmationPopup = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()
  const { mutateAsync: deleteConnection } = useDeleteMergeConnection(integrationId)

  return (
    <Item
      color={Token.color.danger}
      use="button"
      onClick={async evnt => {
        evnt.preventDefault()
        confirmationPopup.show({
          label: 'Are you sure?',
          body: `After disabling this integration you won’t be able to use ${title} sync`,
          onConfirm: async () => {
            try {
              await deleteConnection(undefined)
              showStatusPopup({ title: 'Integration disabled' })
              onSuccess?.()
            } catch (error) {
              showStatusPopup({
                title: 'Cannot disable integration',
                description: getStringMessageFromError(error),
                status: 'error',
              })
            }
          },
        })
      }}
    >
      <Item.Content>
        <Flex gap="s-16">
          <Icon name="Unlink" />
          <Item.Title>Disconnect {title}</Item.Title>
        </Flex>
      </Item.Content>
    </Item>
  )
}
