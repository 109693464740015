import { ColoredPercent } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import RiskCircles from '@components/ColumnInserts/RiskCircles/RiskCircles'
import { getStatusThemeColor } from '@components/CommonSC/General'
import Loader from '@components/CommonSC/Loader'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { Box, Flex, MoreBar, Tag, Icon } from '@revolut/ui-kit'
import { goBack } from '@src/actions/RouterActions'
import { useGetOrganisationSettings, useGlobalSettings } from '@src/api/settings'
import {
  silentDeleteTeam,
  updateTeamStatistics,
  useGetTeam,
  useUpdateTeam,
  useGetTeamApprovals,
} from '@src/api/teams'
import { ROUTES } from '@src/constants/routes'
import { TabBarNavigationNames } from '@src/constants/tabBarNavigation'
import { SlackButton } from '@src/features/CommunicationGroups/CommunicationActions'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import DeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/DeleteOrgUnitButton'
import { TabBarNavigationEditable } from '@src/features/TabBarNavigationEditable/TabBarNavigationEditable'
import { Statuses } from '@src/interfaces'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { TalentStatsInterface } from '@src/interfaces/functions'
import { MeetingTrackerEntity } from '@src/interfaces/meetingsTracker'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import { UpdateButton } from '@src/pages/Forms/FormButtons'
import { MeetingsTracker } from '@src/pages/Forms/MeetingsTracker/MeetingsTracker'
import { AnalyticsDashboards } from '@src/pages/Forms/TeamForm/AnalyticsDashboards/AnalyticsDashboards'
import TeamCX from '@src/pages/Forms/TeamForm/CX'
import TeamAccessRequests from '@src/pages/Forms/TeamForm/DataAccess/DataAcess'
import { Engagement } from '@src/pages/Forms/TeamForm/Engagement/Engagement'
import TeamKpi from '@src/pages/Forms/TeamForm/KPI'
import TeamRisk from '@src/pages/Forms/TeamForm/Risk/Risk'
import TeamRoadmap from '@src/pages/Forms/TeamForm/Roadmap/Roadmap'
import Talent from '@src/pages/Forms/TeamForm/Talent/Talent'
import Page404 from '@src/pages/Page404/Page404'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferButton'
import { TeamPageHeader } from '@src/pages/Team/Layout/common/TeamPageHeader'
import { DetailsSidebar } from '@src/pages/Team/Layout/DetailsSidebar'
import { Label } from '@components/Label/Label'
import {
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import { EntityPermissions, FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import upperFirst from 'lodash/upperFirst'
import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useParams } from 'react-router-dom'
import CXCircles from '@src/components/ColumnInserts/CXCircles/CXCircles'
import { Issues } from '@src/pages/Team/Layout/Issues/Issues'
import { IssuesCircles } from '@src/features/IssuesCircles/IssuesCircles'
import { useTeamIssuesCounts } from '@src/api/issues'
import { ArchiveButton } from '@src/features/SettingsButtons/ArchiveButton/ArchiveButton'
import useApprovalFlow from '@src/features/ApprovalFlow/useApprovalFlow'
import { getTalentStats } from '@src/api/talent'
import { useCanView } from '@src/pages/Team/helpers'
import { GoalsTab } from '@src/features/Goals/GoalsTab'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EntityTypes, API } from '@src/constants/api'
import { RedirectToFirstTab } from '@src/features/TabBarNavigationEditable/RedirectToFirstTab'
import TeamRequisitions from '@src/pages/Forms/TeamForm/Requisitions/Requisitions'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import { TEAM_DEFAULT_ICON } from '@src/constants/common'
import { useIsNewTable } from '@components/TableV2/hooks'

const backUrl = ROUTES.ORGANISATION.TEAMS.TEAMS

export const TeamLayout = () => {
  const isNewTable = useIsNewTable()
  const params = useParams<{ id: string }>()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const user = useSelector(selectUser)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { data: team, isLoading, isError, error } = useGetTeam(params.id)
  const entity = useMemo<OrgEntityInterface | undefined>(() => {
    return team
      ? {
          type: EntityTypes.teams,
          data: team,
        }
      : undefined
  }, [team])
  const [stats, setStats] = useState<TalentStatsInterface>()

  const canView = useCanView(team)

  const { data: settings } = useGetOrganisationSettings()
  const {
    settings: { engagement_enabled },
  } = useGlobalSettings()
  const dataAccessEnabled = !!settings?.data_access_requests?.enabled
  const approvalsEnabled = !!settings?.enable_teams_approvals
  const findingsEnabled = !!settings?.findings?.enabled

  const { approvalButtons, approvalFlow } = useApprovalFlow({
    data: approvalsEnabled ? team : undefined,
    useGetApprovals: useGetTeamApprovals,
    statusFieldName: 'approval_status',
    api: useUpdateTeam,
  })

  const { data: issuesCounts } = useTeamIssuesCounts(team?.id)

  useEffect(() => {
    if (params.id) {
      getTalentStats('team__id', params.id).then(res => {
        if (res.data) {
          setStats(res.data)
        }
      })
    }
  }, [])

  if (isLoading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (isError) {
    return <FormErrorGuard error={error} />
  }

  if (!team || !params.id) {
    return <Page404 />
  }

  const isTeamOwner = user.id === team.team_owner?.id
  const canViewMeetingsTab =
    (isTeamOwner || permissions.includes(PermissionTypes.ViewMeeting)) &&
    featureFlags.includes(FeatureFlags.OneToOnes) &&
    !featureFlags.includes(FeatureFlags.CommercialProduct)

  const canViewDataAccess =
    dataAccessEnabled &&
    team?.field_options?.permissions?.includes(PermissionTypes.ViewTeamAccessRequests)

  const canEdit = !!team?.field_options?.actions?.includes?.(EntityPermissions.Change)

  const externalPages = [
    {
      key: 'dataAccess',
      title: 'Data Access',
      path: ROUTES.FORMS.TEAM.ACCESS_REQUESTS,
      to: pathToUrl(ROUTES.FORMS.TEAM.ACCESS_REQUESTS, params),
      component: TeamAccessRequests,
      canView: canViewDataAccess,
    },
  ].filter(({ canView: canViewPage }) => canViewPage)

  const tabs = [
    {
      key: 'kpi',
      title: 'KPI',
      icon: <Icon name="Target" size={15} />,
      quickSummary: <ColoredPercent percent={team.kpi_performance_percent * 100} />,
      path: ROUTES.FORMS.TEAM.KPI,
      to: pathToUrl(ROUTES.FORMS.TEAM.KPI, params),
      component: TeamKpi,
      canView: featureFlags.includes(FeatureFlags.CanAddKPIs),
    },
    {
      key: 'goals',
      title: 'Goals',
      icon: <Icon name="Target" size={15} />,
      quickSummary: Number.isFinite(team.goals_progress_percent) && (
        <ColoredPercent percent={team.goals_progress_percent! * 100} />
      ),
      path: ROUTES.FORMS.TEAM.GOALS.ANY,
      to: pathToUrl(ROUTES.FORMS.TEAM.GOALS.GENERAL, params),
      component: GoalsTab,
      canView: featureFlags.includes(FeatureFlags.CanAddGoals),
    },
    {
      key: 'roadmap',
      title: 'Roadmap',
      icon: <Icon name="Map" size={15} />,
      quickSummary: <ColoredPercent percent={team.roadmap_progress_percent * 100} />,
      path: ROUTES.FORMS.TEAM.ROADMAP,
      to: pathToUrl(ROUTES.FORMS.TEAM.ROADMAP, params),
      component: TeamRoadmap,
      canView: canView.canViewRoadmap,
    },
    {
      key: 'analytics',
      title: 'Analytics',
      icon: <Icon name="BarChart" size={15} />,
      path: ROUTES.FORMS.TEAM.ANALYTICS_DASHBOARDS,
      to: pathToUrl(ROUTES.FORMS.TEAM.ANALYTICS_DASHBOARDS, params),
      component: AnalyticsDashboards,
      canView: canView.canViewAnalytics,
      quickSummary: <QuickSummaryCount count={team.dashboard_count} />,
    },
    {
      key: 'talent',
      title: 'Talent',
      icon: <Icon name="People" size={15} />,
      path: ROUTES.FORMS.TEAM.TALENT.ANY,
      to: pathToUrl(ROUTES.FORMS.TEAM.TALENT.GENERAL, params),
      quickSummary: getTalentStatsQuickSummary({
        headcount: team?.headcount,
        nips: stats?.nips,
      }),
      component: Talent,
      canView: canView.canViewTalent,
    },
    {
      key: 'requisitions',
      title: 'Requisitions',
      icon: <Icon name="AddContact" size={15} />,
      path: ROUTES.FORMS.TEAM.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.TEAM.REQUISITIONS, params),
      component: TeamRequisitions,
      quickSummary: <QuickSummaryCount count={team.requisition_remaining_headcount} />,
      canView: canView.canViewRequisitions,
    },
    {
      key: 'issues',
      title: 'Issues',
      icon: <Icon name="Bug" size={15} />,
      path: ROUTES.FORMS.TEAM.ISSUES.ANY,
      to: findingsEnabled
        ? pathToUrl(ROUTES.FORMS.TEAM.ISSUES.FINDING, params)
        : pathToUrl(ROUTES.FORMS.TEAM.ISSUES.TECHNICAL, params),
      component: Issues,
      canView: canView.canViewIssues,
      quickSummary: <IssuesCircles counts={issuesCounts} showSum={isNewTable} />,
    },
    {
      key: 'meetings',
      title: 'Meetings',
      icon: <Icon name="Chat" size={15} />,
      path: ROUTES.FORMS.TEAM.MEETINGS,
      to: pathToUrl(ROUTES.FORMS.TEAM.MEETINGS, params),
      component: MeetingsTracker,
      canView: !!team.id && canViewMeetingsTab,
    },
    {
      key: 'cx',
      title: 'CX',
      icon: <Icon name="Form" size={15} />,
      path: ROUTES.FORMS.TEAM.CX.ANY,
      to: pathToUrl(ROUTES.FORMS.TEAM.CX.ALL, params),
      quickSummary: <CXCircles data={team} />,
      component: TeamCX,
      canView: canView.canViewCX,
    },
    {
      key: 'engagement',
      title: 'Engagement',
      icon: <Icon name="Heart" size={15} />,
      path: ROUTES.FORMS.TEAM.ENGAGEMENT.ANY,
      to: pathToUrl(ROUTES.FORMS.TEAM.ENGAGEMENT.CATEGORIES, params),
      component: Engagement,
      canView:
        engagement_enabled &&
        !!team.id &&
        permissions.includes(PermissionTypes.ViewEngagementTabs),
    },
    {
      key: 'risk',
      title: 'Risk',
      icon: <Icon name="ChangePasscode" size={15} />,
      path: ROUTES.FORMS.TEAM.RISK.ANY,
      to: pathToUrl(ROUTES.FORMS.TEAM.RISK.SUMMARY, params),
      quickSummary: <RiskCircles data={team} />,
      component: TeamRisk,
      canView: canView.canViewRisk,
    },
  ]

  const filteredTabs = tabs.filter(organisationSubtab => {
    if (organisationSubtab.canView === undefined) {
      return true
    }

    return organisationSubtab.canView
  })

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper fullWidth>
        <Switch>
          {externalPages.map(page => (
            <Route exact key={page.key} path={page.path}>
              {page.title && <TeamPageHeader data={team} title={page.title} />}
              <page.component data={team} />
            </Route>
          ))}
          <Route>
            <PageHeader
              title={
                <PageHeader.Title
                  title={team.name}
                  avatar={
                    <EntityAvatar
                      data={team}
                      defaultIcon={TEAM_DEFAULT_ICON}
                      api={useUpdateTeam}
                      apiUrl={API.TEAMS}
                      canEdit={canEdit}
                    />
                  }
                  labels={
                    <>
                      {team.status === Statuses.archived ? (
                        <Tag variant="outlined" color={getStatusThemeColor(team.status)}>
                          {upperFirst(team.status)}
                        </Tag>
                      ) : null}
                      {team.approval_status !== ApprovalStatuses.Approved &&
                      (approvalsEnabled ||
                        team.approval_status !== ApprovalStatuses.Pending) ? (
                        <>
                          <Tag
                            variant="outlined"
                            color={getStatusThemeColor(team.approval_status)}
                          >
                            {upperFirst(team.approval_status)}
                          </Tag>
                        </>
                      ) : null}
                      {team.department ? (
                        <Label
                          name={team.department.name}
                          icon="Bank"
                          url={pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, {
                            id: team.department.id,
                          })}
                        />
                      ) : null}
                      {team.team_owner ? (
                        <Label
                          name={team.team_owner.full_name}
                          icon="Profile"
                          url={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                            id: team.team_owner.id,
                          })}
                        />
                      ) : null}
                    </>
                  }
                  actions={
                    <>
                      <MoreBar maxCount={approvalButtons !== null ? 5 : 3}>
                        {approvalButtons}
                        <SlackButton group={team.communication_group} />
                        <MoreBar.Action
                          useIcon="16/InfoOutline"
                          onClick={() => setSidebarOpen(true)}
                        >
                          View details
                        </MoreBar.Action>
                        {canEdit ? (
                          <MoreBar.Action
                            useIcon="Pencil"
                            use={InternalLink}
                            to={pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, { id: team.id })}
                          >
                            {approvalsEnabled &&
                            team.approval_status === ApprovalStatuses.Rejected
                              ? 'Edit & move to pending'
                              : 'Edit'}
                          </MoreBar.Action>
                        ) : null}
                        <PermissionTransferButton
                          values={team}
                          path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.TEAM}
                        />
                        {canViewDataAccess ? (
                          <MoreBar.Action
                            useIcon="LockClosed"
                            use={InternalLink}
                            to={pathToUrl(ROUTES.FORMS.TEAM.ACCESS_REQUESTS, {
                              id: team.id,
                            })}
                          >
                            View data access
                          </MoreBar.Action>
                        ) : null}
                        <UpdateButton update={updateTeamStatistics} id={team.id} />
                        <ArchiveButton
                          data={team}
                          isVisible={!!team?.id}
                          entityPermissions={EntityPermissions.Change}
                          unArchiveStatus={Statuses.active}
                          api={useUpdateTeam}
                          showDialog
                        />
                        <DeleteOrgUnitButton
                          onAfterDelete={() => goBack(backUrl)}
                          deleteApi={silentDeleteTeam}
                          prefix="team"
                          displayName="team"
                          data={team}
                        />
                      </MoreBar>
                    </>
                  }
                />
              }
              backUrl={backUrl}
            >
              <Box my="s-16">
                <TabBarNavigationEditable
                  tabs={filteredTabs}
                  name={TabBarNavigationNames.Team}
                />
              </Box>
            </PageHeader>

            <Flex flex="1 0" flexDirection="column" width="100%">
              <Switch>
                {filteredTabs.map(tab => (
                  <Route exact path={tab.path} key={tab.path}>
                    <tab.component
                      data={team}
                      meetingEntityType={MeetingTrackerEntity.team}
                    />
                  </Route>
                ))}
                <RedirectToFirstTab
                  name={TabBarNavigationNames.Team}
                  tabs={filteredTabs}
                />
              </Switch>
            </Flex>

            <DetailsSidebar
              open={sidebarOpen}
              onClose={() => setSidebarOpen(false)}
              team={team}
              approvalFlow={approvalFlow}
            />
          </Route>
        </Switch>
      </PageWrapper>
    </OrgEntityProvider>
  )
}
