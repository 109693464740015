import { Avatar, Group, Icon, Item, Text, Token } from '@revolut/ui-kit'
import React from 'react'
import { MeetingItem, MeetingStatus } from './interfaces'
import { format } from 'date-fns'
import { meetingStatusToItemPropsMap } from './utils'

// remove after back-end will be connected
const mockedData = [
  {
    id: 1,
    date: new Date().toISOString(),
    status: MeetingStatus.Upcoming,
    range: '9:30 - 10:00',
  },
  {
    id: 2,
    date: new Date().toISOString(),
    status: MeetingStatus.Completed,
    range: '9:30 - 10:00',
  },
  {
    id: 3,
    date: new Date().toISOString(),
    status: MeetingStatus.Completed,
    range: '9:30 - 10:00',
  },
  {
    id: 4,
    date: new Date().toISOString(),
    status: MeetingStatus.Completed,
    range: '9:30 - 10:00',
  },
]

interface Props {
  selectedMeeting: MeetingItem | undefined
  setSelectedMeeting: (target: MeetingItem | undefined) => void
}

export const MeetingsList = ({ selectedMeeting, setSelectedMeeting }: Props) => {
  return (
    <Group>
      {mockedData.map(item => (
        <Item
          key={item.id}
          use="button"
          variant="compact"
          aria-pressed={selectedMeeting?.id === item.id}
          onClick={() => {
            setSelectedMeeting(item)
          }}
        >
          <Item.Avatar>
            <Avatar
              color={Token.color.foreground}
              useIcon="Calendar"
              textStyle="emphasis1"
            >
              {selectedMeeting?.id === item.id && (
                <Avatar.Badge
                  bg={Token.color.foreground}
                  position="bottom-right"
                  useIcon={<Icon name="Check" size={12} />}
                />
              )}
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Title>{format(new Date(item.date), 'MMM d')}</Item.Title>
            <Item.Description color={meetingStatusToItemPropsMap[item.status].color}>
              <Text variant="body2">
                {meetingStatusToItemPropsMap[item.status].label}
              </Text>
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <Item.Value>
              <Text variant="body1" color={Token.color.greyTone50}>
                {item.range}
              </Text>
            </Item.Value>
          </Item.Side>
        </Item>
      ))}
    </Group>
  )
}
