import {
  MeetingTemplateInterface,
  TemplateItemContentType,
  TemplateItemRole,
} from '@src/interfaces/meetings'
import { MeetingTemplateEditStep } from './interfaces'

export const isStepDone = (
  step: MeetingTemplateEditStep,
  values?: MeetingTemplateInterface,
) => {
  switch (step) {
    case 'audience':
      return !!values?.audiences?.length
    case 'general':
      return !!(values?.name && values?.duration)
    case 'fields':
      return values?.items?.length || values?.enable_ai
    default:
      return false
  }
}

export const itemFieldsSetupEntitiesRecord = {
  [TemplateItemRole.TalkingPoints]: {
    title: 'Talking points',
    content_type: TemplateItemContentType.TextList,
    content: [],
    role: TemplateItemRole.TalkingPoints,
    is_private: false,
    is_available_before_start: true,
  },
  [TemplateItemRole.ActionItems]: {
    title: 'Action items',
    content_type: TemplateItemContentType.TextCheckbox,
    content: [],
    role: TemplateItemRole.ActionItems,
    is_private: false,
    is_available_before_start: false,
  },
  [TemplateItemRole.PerformanceRating]: {
    title: 'Performance rating',
    content_type: TemplateItemContentType.DropdownSingleChoice,
    content: [
      { value: 'unsatisfactory', name: 'unsatisfactory' },
      { value: 'average_minus', name: 'average minus' },
      { value: 'average_plus', name: 'average plus' },
      { value: 'strong', name: 'strong' },
      { value: 'exceptional', name: 'exceptional' },
    ],
    role: TemplateItemRole.PerformanceRating,
    is_private: true,
    is_available_before_start: false,
  },
  [TemplateItemRole.PersonalNotes]: {
    title: 'Personal notes',
    content_type: TemplateItemContentType.Text,
    content: [],
    role: TemplateItemRole.PersonalNotes,
    is_private: true,
    is_available_before_start: false,
  },
}
