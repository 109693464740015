import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'lape'
import { BoxProps, Search, SearchVariant, Box } from '@revolut/ui-kit'
import { FilterByInterface } from '@src/interfaces/data'
import debounce from 'lodash/debounce'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useIsNewTable } from '@components/TableV2/hooks'

export type OnFilterHandler = (
  filter: FilterByInterface,
  resetDefaultFilters?: boolean,
  forceReset?: boolean,
) => void

interface SearchTableProps extends BoxProps {
  placeholder?: string
  onFilter: OnFilterHandler
  columnName?: string
  variant?: SearchVariant
  disabled?: boolean
}

const SearchTableInput = ({
  onFilter,
  placeholder = 'Search',
  columnName = 'search',
  variant = 'default',
  disabled = false,
}: SearchTableProps) => {
  const isNewTable = useIsNewTable()
  const [val, setVal] = useState('')
  const { query } = useQuery()
  const searchThrottled = useCallback(
    debounce(
      (q: string) => {
        return onFilter(
          {
            columnName,
            filters: q
              ? [
                  {
                    id: q,
                    name: q,
                  },
                ]
              : [],
          },
          true,
          true,
        )
      },
      1000,
      { leading: false, trailing: true },
    ),
    [],
  )

  useEffect(() => {
    if (query?.search) {
      setVal(query.search)
    }
  }, [])

  if (isNewTable && variant === 'default') {
    return null
  }

  if (!isNewTable && variant === 'compact') {
    return null
  }

  if (variant === 'default') {
    return (
      <Box width="100%" minWidth={200} maxWidth={443}>
        <Search
          onChange={q => {
            setVal(q)
            searchThrottled(q)
          }}
          value={val}
          placeholder={placeholder}
          disabled={disabled}
        />
      </Box>
    )
  }

  return (
    <Search
      onChange={q => {
        setVal(q)
        searchThrottled(q)
      }}
      value={val}
      placeholder={placeholder}
      variant={variant}
      disabled={disabled}
    />
  )
}

export default connect(SearchTableInput)
