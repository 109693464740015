import React, { ComponentType, useMemo } from 'react'
import { Badge, Box, Token, VStack } from '@revolut/ui-kit'
import { PageWrapper } from '@src/components/Page/Page'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'

import { Route, Switch, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageBody } from '@src/components/Page/PageBody'
import Form from '@src/features/Form/Form'
import { FormActions } from './components/FormActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { isStepDone } from './helpers'
import { PageActions } from '@src/components/Page/PageActions'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { MeetingTemplateEditStep } from './interfaces'
import { General } from './tabs/General'
import { Fields } from './tabs/Fields'
import { Audience } from './tabs/Audience'
import { meetingsTemplatesFormRequests, useGetMeetingTemplate } from '@src/api/meetings'
import { MeetingTemplateInterface } from '@src/interfaces/meetings'
import { PreviewHeader } from './components/PreviewHeader'
import { PreviewGeneralSection } from './components/PreviewGeneralSection'
import { PreviewFieldsSection } from './components/PreviewFieldsSection'
import { PreviewAudienceSection } from './components/PreviewAudienceSection'
import { navigateTo } from '@src/actions/RouterActions'

const formTabs: (TabBarTableNavigationInterface & {
  component: ComponentType<any>
  key: MeetingTemplateEditStep
})[] = [
  {
    title: 'General Info',
    key: 'general',
    component: General,
  },
  {
    title: 'Fields',
    key: 'fields',
    component: Fields,
  },
  {
    title: 'Audience',
    key: 'audience',
    component: Audience,
  },
]

const MeetingTemplateFormContent = () => {
  const { id, step } = useParams<{ id?: string; step: MeetingTemplateEditStep }>()
  const { values } = useLapeContext<MeetingTemplateInterface>()

  const title = id ? 'Edit meeting template' : 'New meeting template'

  const subTitle = id ? '' : 'Create a new meeting template'

  const tabs = useMemo(() => {
    return formTabs.map((tab, idx) => {
      const path = pathToUrl(ROUTES.FORMS.MEETING_TEMPLATE.EDIT, { id, step: tab.key })

      return {
        ...tab,
        path,
        disabled: !values.id,
        url: path,
        preTitle: (
          <Box mr="s-8">
            <Badge
              useIcon={isStepDone(tab.key, values) ? 'Check' : undefined}
              size={16}
              bg={
                isStepDone(tab.key, values)
                  ? Token.color.success
                  : step === tab.key
                  ? Token.color.greyTone50
                  : Token.color.greyTone10
              }
            >
              {!isStepDone(tab.key, values) ? idx + 1 : null}
            </Badge>
          </Box>
        ),
      }
    })
  }, [id, step, formTabs, values])

  return (
    <PageWrapper>
      <PageHeader
        title={title}
        subtitle={subTitle}
        backUrl={ROUTES.PERFORMANCE.ONE_TO_ONE.TEMPLATES}
      />
      <Box mb="s-16">
        <TabBarNavigation tabs={tabs} />
      </Box>
      <Switch>
        {tabs.map(tab => (
          <Route exact key={tab.key} path={tab.path}>
            <PageBody maxWidthMd={Token.breakpoint.md}>
              <VStack gap="s-16">
                <tab.component />
              </VStack>
            </PageBody>
            <PageActions>
              <FormActions currentStep={tab.key} />
            </PageActions>
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

const Preview = () => {
  const { id } = useParams<{ id: string }>()

  const onEdit = (step: MeetingTemplateEditStep, shouldGoBackOnSave: boolean = true) => {
    navigateTo(pathToUrl(ROUTES.FORMS.MEETING_TEMPLATE.EDIT, { step, id: data?.id }), {
      shouldGoBackOnSave,
    })
  }

  const { data, isLoading } = useGetMeetingTemplate(id)
  return (
    <PageWrapper>
      <PageBody gap="s-16" maxWidthMd={Token.breakpoint.md}>
        <PreviewHeader onEdit={onEdit} data={data} isLoading={isLoading} />
        <PreviewGeneralSection onEdit={onEdit} data={data} isLoading={isLoading} />
        <PreviewFieldsSection onEdit={onEdit} data={data} isLoading={isLoading} />
        <PreviewAudienceSection onEdit={onEdit} data={data} isLoading={isLoading} />
      </PageBody>
    </PageWrapper>
  )
}

export const MeetingTemplateForm = () => {
  return (
    <Switch>
      <Route path={ROUTES.FORMS.MEETING_TEMPLATE.PREVIEW}>
        <Preview />
      </Route>
      <Route exact path={ROUTES.FORMS.MEETING_TEMPLATE.EDIT}>
        <Form api={meetingsTemplatesFormRequests}>
          <MeetingTemplateFormContent />
        </Form>
      </Route>
    </Switch>
  )
}
