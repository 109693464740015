// TODO: https://revolut.atlassian.net/browse/REVC-7392 change mocked enums/interfaces.
export enum MeetingStatus {
  Upcoming = 'upcoming',
  Completed = 'completed',
}

export interface MeetingItem {
  id: number
  date: string
  status: MeetingStatus
  range: string
}
