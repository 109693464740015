import { Color, MoreBar, Text, TextButton, Token, useMoneyFormat } from '@revolut/ui-kit'
import get from 'lodash/get'
import pluralize from 'pluralize'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Stat from '@components/Stat/Stat'
import { navigateTo } from '@src/actions/RouterActions'
import { getPayCycleEmployeeReportsTableRequests } from '@src/api/payrollV2'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useTable } from '@src/components/Table/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import {
  payCycleReportsActionsColumn,
  payCycleReportsChangesColumn,
  payCycleReportsDepartmentColumn,
  payCycleReportsEmployeeNameColumn,
  payCycleReportsGrossSalaryColumn,
  payCycleReportsRoleColumn,
  payCycleReportsStatusColumn,
} from '@src/constants/columns/payCycleReportsV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { AsyncReportExportButton } from '@src/features/PayrollExportReport'
import { RowInterface } from '@src/interfaces/data'
import {
  PayCycleReportStatsInterface,
  PayCycleReportTableItemInterface,
} from '@src/interfaces/payrollV2'
import { pathToUrl } from '@src/utils/router'

import { CommonTabProps, CycleOption } from '../common'

const getRow = (
  onRowClick: (id: number) => void,
  moneyFormat: ReturnType<typeof useMoneyFormat>,
): RowInterface<PayCycleReportTableItemInterface> => ({
  cells: [
    {
      ...payCycleReportsEmployeeNameColumn,
      width: 360,
    },
    {
      ...payCycleReportsGrossSalaryColumn,
      width: 100,
      insert: ({ data }) =>
        data.salary_amount ? (
          <Text>
            {moneyFormat({ amount: data.salary_amount, currency: data.salary_currency })}
          </Text>
        ) : (
          '-'
        ),
    },
    {
      ...payCycleReportsRoleColumn,
      width: 150,
    },
    {
      ...payCycleReportsDepartmentColumn,
      width: 150,
    },
    {
      ...payCycleReportsChangesColumn,
      width: 100,
    },
    {
      ...payCycleReportsStatusColumn,
      width: 100,
    },
    {
      ...payCycleReportsActionsColumn,
      width: 200,
      insert: ({ data }) => {
        return (
          <TextButton
            onClick={() => {
              onRowClick(data.id)
            }}
          >
            Preview details
          </TextButton>
        )
      },
    },
  ],
})

const getOriginalProviderId = (payGroup: CycleOption) => {
  // is needed to avoid TS complaining about inconsistent interfaces
  // since BE API returns data in different format than it accepts (IdAndName on submit / plain id on fetch)
  return get(payGroup, 'pay_group.payroll_provider')
}

const getExportProviderId = (payGroup: CycleOption) => {
  const providerId = getOriginalProviderId(payGroup)

  if (['adp_c2', 'adp_g2'].includes(providerId)) {
    return providerId
  }
  return 'revolut'
}

type Props = CommonTabProps & {
  onClickReport: (id: number) => void
}
export const PayCycleEmployeeReportsTable = ({ selectedCycle, onClickReport }: Props) => {
  const params = useParams<{ id: string }>()
  const cycleId = selectedCycle?.id || params.id

  const moneyFormat = useMoneyFormat()
  const table = useTable<PayCycleReportTableItemInterface, PayCycleReportStatsInterface>(
    getPayCycleEmployeeReportsTableRequests(cycleId),
  )

  useEffect(() => {
    table.refresh()
  }, [cycleId])

  const successOnZero = (
    count: number | undefined,
    defaultColor: Color = Token.color.greyTone50,
  ) => (count === 0 ? Token.color.success : defaultColor)

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label="Total"
          color={Token.color.greyTone50}
          val={table.stats?.total_reports}
        />
        <Stat
          label={pluralize('Change', table.stats?.total_changes)}
          color={Token.color.greyTone50}
          val={table.stats?.total_changes}
        />
        <Stat
          label={pluralize('Issue', table.stats?.total_issues)}
          color={successOnZero(table.stats?.total_issues)}
          val={table.stats?.total_issues}
        />
        <Stat
          label={pluralize('Critical', table.stats?.total_criticals)}
          color={successOnZero(table.stats?.total_criticals, Token.color.danger)}
          val={table.stats?.total_criticals}
        />
        <Stat
          label={pluralize('Warning', table.stats?.total_warnings)}
          color={successOnZero(table.stats?.total_criticals, Token.color.warning)}
          val={table.stats?.total_warnings}
        />
        <Stat
          label="Approved"
          color={
            table.stats?.total_approved === 0
              ? Token.color.greyTone50
              : Token.color.success
          }
          val={table.stats?.total_approved}
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {selectedCycle && (
            <>
              <AsyncReportExportButton
                payCycleId={selectedCycle.id}
                payrollProvider={getExportProviderId(selectedCycle)}
                isDeel={getOriginalProviderId(selectedCycle) === 'deel'}
              />
              <HideIfCommercial>
                <MoreBar.Action
                  useIcon="BalanceSheet"
                  onClick={() =>
                    navigateTo(
                      pathToUrl(
                        ROUTES.APPS.PAYROLL.PAY_CYCLES_DOWNLOAD_REPORTS_TABLE.DOWNLOAD,
                        { id: selectedCycle.id },
                      ),
                    )
                  }
                >
                  Download reports
                </MoreBar.Action>
              </HideIfCommercial>
            </>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          hideCount
          name={TableNames.PayCycleEmployeeReports}
          row={getRow(onClickReport, moneyFormat)}
          useWindowScroll
          emptyState={<EmptyTableRaw title="No employee reports for this cycle" />}
          {...table}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
