import { IdAndName } from '@src/interfaces/index'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { AuditStatuses } from '@src/interfaces/audit'

export const IssueTypes = [
  'user_issue',
  'technical_bug',
  'user',
  'product_audit',
] as const
export type IssueType = typeof IssueTypes[number]

export type IssueSeverity =
  | 'minor'
  | 'medium'
  | 'major'
  | 'critical'
  | 'blocker'
  | 'unknown'

export interface IssueInterface {
  issue_id: string
  issue_type: IdAndName<string>
  category: IdAndName<IssueType>
  status: IdAndName<AuditStatuses>
  summary: string
  summary_link: string | null
  assignee: EmployeeOptionInterface
  team: IdAndName
  days_left: number | null
  due_date: string | null
  severity: IdAndName<IssueSeverity> | null
  issue_link: string
  department: IdAndName
  created_date_time: string
  severity_sorter: number
  average_count: number
  trend: number
}

export type IssuesCounts = Record<IssueSeverity, number>
export type IssuesCategoryCounts = Record<IssueType, Record<IssueSeverity, number>>
