import React from 'react'
import {
  Avatar,
  Group,
  Header,
  HeaderSkeleton,
  Icon,
  IconName,
  Item,
  ItemSkeleton,
  Subheader,
  SubheaderSkeleton,
} from '@revolut/ui-kit'
import { MeetingEvent, OneToOneMeeting } from '@src/interfaces/meetings'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  defaultMeetingDuration,
  defaultMeetingRecurrence,
  OneToOneMeetingTemplate,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/hooks/useMeetingTemplate'
import { formatRRule, getTotalTimeFromMinutes } from '@src/utils/format'
import { formatMeetingEventTimeDiff } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/formatters/meetingSeries'
import { UserAvatar } from '@components/UserWithAvatar/UserAvatar'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface Props {
  isLoading: boolean
  meeting: OneToOneMeeting
  events: MeetingEvent[]
  template: OneToOneMeetingTemplate | null
}

const getTitle = ({ name }: EmployeeOptionInterface) => `Set 1:1 meeting with ${name}`
const getDescription = (
  { name }: EmployeeOptionInterface,
  events: MeetingEvent[],
  template: OneToOneMeetingTemplate | null,
) => {
  if (events.length > 0) {
    return `We found a recurring meeting with ${name} in your calendar. You can use this as you 1:1, or schedule a different meeting.`
  }

  const recurrence =
    template !== null ? formatRRule(template.recurrence) : defaultMeetingRecurrence
  const duration = template !== null ? template.duration : defaultMeetingDuration

  return `We recommend a ${recurrence.toLowerCase()} ${getTotalTimeFromMinutes(
    duration,
  )} meeting`
}

export const PopupBody = ({ isLoading, meeting, events, template }: Props) => {
  const title = getTitle(meeting.employee)
  const description = getDescription(meeting.employee, events, template)
  const makeItemClickHandler = (event?: MeetingEvent) => () =>
    navigateTo(
      pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.ONE_TO_ONE.SCHEDULE,
        { employeeId: meeting.employee.id, meetingId: meeting.id, eventId: event?.id },
      ),
    )

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <>
      <Header variant="bottom-sheet">
        <Header.Title>{title}</Header.Title>
        <Header.Description>{description}</Header.Description>
      </Header>
      {events.length > 0 && (
        <>
          <Subheader>
            <Subheader.Title>Existing meeting</Subheader.Title>
            <Subheader.Side>
              <Icon name="LogoGoogle|image" size={16} />
            </Subheader.Side>
          </Subheader>
          <Group>
            {events.map(event => (
              <MeetingItem
                key={event.id}
                title={event.title}
                description={formatMeetingEventTimeDiff(event)}
                employee={meeting.employee}
                onClick={makeItemClickHandler(event)}
              />
            ))}
          </Group>
        </>
      )}
      <Subheader>
        <Subheader.Title>Create a new event</Subheader.Title>
      </Subheader>
      <MeetingItem
        title="Find a time slot"
        iconName="Calendar"
        onClick={makeItemClickHandler()}
      />
    </>
  )
}

const Skeleton = () => (
  <>
    <HeaderSkeleton variant="bottom-sheet" />
    <SubheaderSkeleton />
    <Group>
      <ItemSkeleton />
      <ItemSkeleton />
    </Group>
    <SubheaderSkeleton>
      <SubheaderSkeleton.Title />
    </SubheaderSkeleton>
    <ItemSkeleton>
      <ItemSkeleton.Avatar />
      <ItemSkeleton.Content>
        <ItemSkeleton.Title />
      </ItemSkeleton.Content>
    </ItemSkeleton>
  </>
)

interface MeetingItemProps {
  title: string
  description?: string
  iconName?: IconName
  employee?: EmployeeOptionInterface
  onClick: VoidFunction
}

const MeetingItem = ({
  title,
  description,
  iconName,
  employee,
  onClick,
}: MeetingItemProps) => (
  <Item use="button" variant="disclosure" onClick={onClick}>
    <Item.Avatar>
      {employee !== undefined ? (
        <UserAvatar {...employee} size={40} />
      ) : (
        <Avatar useIcon={iconName} />
      )}
    </Item.Avatar>
    <Item.Content>
      <Item.Title>{title}</Item.Title>
      {description && <Item.Description>{description}</Item.Description>}
    </Item.Content>
  </Item>
)
