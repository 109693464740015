import React, { useState } from 'react'
import {
  VStack,
  Item,
  Flex,
  Text,
  Button,
  Image,
  HStack,
  ActionButton,
  Avatar,
  chain,
  IconButton,
  Token,
  Box,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FormattedMessage } from 'react-intl'
import pluralize from 'pluralize'
import { MeetingTemplateInterface } from '@src/interfaces/meetings'
import { AddAudienceSidebar } from '@src/components/AddAudienceSidebar'

export const Audience = () => {
  const { values } = useLapeContext<MeetingTemplateInterface>()
  const [isSidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <Item>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="template.form.audience.title"
              defaultMessage="Audience"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="template.form.audience.description"
              defaultMessage="Who should have access to this meeting template?"
            />
          </Item.Description>
          <Box>
            <Flex
              flexDirection="column"
              pt="s-8"
              justifyContent={values?.audiences?.length ? 'flex-start' : 'center'}
            >
              {values?.audiences?.length ? (
                <VStack gap="s-16">
                  <HStack gap="s-16">
                    <ActionButton useIcon="Plus" onClick={() => setSidebarOpen(true)}>
                      <FormattedMessage
                        id="template.form.audience.addButton"
                        defaultMessage="Select audience"
                      />
                    </ActionButton>
                  </HStack>

                  {values?.audiences?.map(audience => (
                    <Item
                      key={audience.id}
                      p="s-8"
                      style={{ border: `1px solid ${Token.color.greyTone10}` }}
                    >
                      <Item.Avatar>
                        <Avatar useIcon="People" />
                      </Item.Avatar>
                      <Item.Content>
                        <Item.Title>{audience.name}</Item.Title>
                        <Item.Description>
                          {chain(
                            audience.audience_type.name,
                            `${pluralize('member', audience.headcount, true)}`,
                          )}
                        </Item.Description>
                      </Item.Content>
                      <Item.Side>
                        <IconButton
                          useIcon="Delete"
                          variant="default"
                          color={Token.color.greyTone10}
                          onClick={() => {
                            values.audiences = values.audiences.filter(
                              currentAudience => currentAudience.id !== audience.id,
                            )
                          }}
                        />
                      </Item.Side>
                    </Item>
                  ))}
                </VStack>
              ) : (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  gap="s-16"
                >
                  <Image
                    src="https://assets.revolut.com/assets/3d-images-v2/3D395.png"
                    size={104}
                  />
                  <Flex flexDirection="column" alignItems="center">
                    <Text variant="emphasis1">
                      <FormattedMessage
                        id="template.form.audience.empty.title"
                        defaultMessage="Who will have access to this 1:1 template?"
                      />
                    </Text>
                    <Text variant="body2" color={Token.color.greyTone50}>
                      <FormattedMessage
                        id="template.form.audience.empty.description"
                        defaultMessage="Select teams, departments or particular roles"
                      />
                    </Text>
                  </Flex>
                  <Button
                    useIcon="Plus"
                    variant="text"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <FormattedMessage
                      id="template.form.audience.addButton"
                      defaultMessage="Select audience"
                    />
                  </Button>
                </Flex>
              )}
            </Flex>
          </Box>
        </Item.Content>
      </Item>
      <AddAudienceSidebar
        open={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
        audiences={values.audiences}
        onAddAudiences={addedAudiences => {
          values.audiences = [...(values.audiences || []), ...addedAudiences]
        }}
      />
    </>
  )
}
