import { AudienceInterface } from '@src/components/AddAudienceSidebar/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { IdAndName } from '@src/interfaces/index'

export enum MeetingStatus {
  Completed = 'completed',
  Scheduled = 'scheduled',
}

export enum EmployeeRelation {
  DirectReport = 'direct report',
  FunctionalReport = 'functional report',
  LineManager = 'line manager',
  FunctionalManager = 'functional manager',
}

export enum TemplateItemRole {
  TalkingPoints = 'talking_points',
  ActionItems = 'action_items',
  PerformanceRating = 'performance_rating',
  PersonalNotes = 'personal_notes',
}

export enum TemplateItemContentType {
  Text = 'text',
  TextList = 'text__list',
  TextCheckbox = 'text__checkbox',
  DropdownSingleChoice = 'dropdown__single_choice',
}

export interface MeetingCompanionInterface {
  id: number
  employee: EmployeeOptionInterface
  employee_relation: EmployeeRelation
  next_meeting_date: string | null
  days_since_last_meeting: number | null
  can_setup_meeting: boolean
  latest_meeting_id: number | null
  next_meeting?: NextMeetingInterface
}

export interface NextMeetingInterface {
  object_id: number
  feedback_description: string
  feedback_link: string
  feedback_event_link: string
  feedback_title: string
}

export interface MeetingInterface {
  id: number
  start: string
  end: string
  status: MeetingStatus
  employee: EmployeeOptionInterface
  link: string
}

export interface MeetingWithManagersStats {
  last_lm_meeting_date: string | null
  last_fm_meeting_date: string | null
}

export interface MeetingWithCompanionDetail {
  last_meeting_date: string | null
  avg_days_between_meetings: number | null
  relation: EmployeeRelation
  relation_employee: EmployeeOptionInterface
  request_employee: EmployeeOptionInterface
}

export enum OneToOneRelation {
  LineManager = 'line_manager',
  FunctionalManager = 'functional_manager',
}

export enum OneToOneEmployeeRelation {
  LineManager = 'line_manager',
  FunctionalManager = 'functional_manager',
  Report = 'report',
}

export interface Attendee extends EmployeeOptionInterface {
  department?: IdAndName
}

interface MeetingSeries {
  id: number
  event_id: string
  start: string
  end: string
  recurrence: string
}

export interface OneToOneMeeting {
  id: number
  manager: Attendee
  employee: Attendee
  relation_type: OneToOneRelation
  employee_relation_type: OneToOneEmployeeRelation
  meeting_series: MeetingSeries | null
}

export interface OneToOneMeetingsStats {
  total_setup_rate: number
  lm_setup_rate: number
  fm_setup_rate: number
}

export enum MeetingEventStatus {
  Completed = 'Completed',
  Planned = 'Planned',
}

export enum MeetingEventCategory {
  OneToOne = 'one_to_one',
}

export enum GoogleCalendarEventStatus {
  ActionRequired = 'needsAction',
  Declined = 'declined',
  Tentative = 'tentative',
  Accepted = 'accepted',
}

interface EventAttendee extends Attendee {
  comment: string
}

export interface GoogleCalendarEventAttendee extends EventAttendee {
  response_status: GoogleCalendarEventStatus
}

export interface MeetingEvent<T extends EventAttendee = EventAttendee> {
  id: number
  title: string
  description: string
  start: string
  end: string
  status: MeetingEventStatus
  category: MeetingEventCategory
  organizer: Attendee
  parent_event_id: string | null
  is_series: boolean
  employee_relation_type: OneToOneEmployeeRelation
  recurrence: string | null
  link: string
  attendees: T[]
  object_id: number
  feedback_title: string
  feedback_description: string
  feedback_link: string | null
  feedback_event_link: string | null
}

export interface ValueName {
  value: string
  name: string
}

export interface TimeSlot {
  start: string
  end: string
}

interface BusySlot {
  busy: TimeSlot[]
}

export interface FreeBusyCalendar {
  kind: string
  timeMin: string
  timeMax: string
  calendars: Record<string, BusySlot>
}

export interface TemplateItemInterface {
  id?: number
  title: string
  is_private: boolean
  is_available_before_start: boolean
  content_type: TemplateItemContentType
  role: TemplateItemRole
  content: string[] | ValueName[]
}

export interface MeetingTemplateInterface {
  id: number
  name: string
  description: string
  frequency: string
  duration: number
  status: string
  audiences: AudienceInterface[]
  items: TemplateItemInterface[]
  created_by: EmployeeOptionInterface
  enable_ai: boolean
}
