import React from 'react'
import isString from 'lodash/isString'
import {
  Flex,
  Skeleton,
  AvatarSize,
  Box,
  AvatarBadgeProps,
  Token,
  getTextStyle,
  TextStyle,
  BoxProps,
} from '@revolut/ui-kit'
import { pathToUrl, pathToUrlWithBaseUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { IdStatuses, AvatarType } from '@src/interfaces/employees'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Link } from 'react-router-dom'
import Tooltip, { placement } from '@components/Tooltip/Tooltip'
import { getInitials } from '@src/utils/employees'
import { UserAvatar } from './UserAvatar'
import { UserLink } from './UserLink'
import UserName from './UserName'
import { Color } from '@revolut/ui-kit/types/dist/constants'

const TooltipLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  justify-self: start;
`

const TooltipAvatar = styled(Tooltip)`
  justify-content: flex-start;
  width: 24px;
`

const AvatarAsTextFlexContainer = styled(Flex)(
  css({
    pointerEvents: 'none',
    color: Token.color.foreground,
    ...getTextStyle(TextStyle.PRIMARY),
  }),
)

export interface UserWithAvatarProps extends Omit<BoxProps, 'id'> {
  avatar?: AvatarType
  name?: string
  id?: string | number
  full_name?: string
  status?: IdStatuses
  isLoading?: boolean
  compact?: boolean
  tooltipPlacement?: placement
  /** Disables the link to the employee's profile */
  asText?: boolean
  size?: AvatarSize
  usePathWithBaseUrl?: boolean
  disableLink?: boolean // asText does the same but also changes styles which is not always needed
  disableTooltip?: boolean
  badgeProps?: AvatarBadgeProps
  progress?: number
  progressColor?: Color
  avatarUrlFunc?: (id: string) => string
  uuid?: string
}

const UserWithAvatar = ({
  id,
  name,
  full_name,
  avatar,
  isLoading,
  status,
  compact,
  tooltipPlacement = 'top',
  asText,
  size,
  usePathWithBaseUrl,
  disableLink,
  disableTooltip = false,
  badgeProps,
  progress,
  progressColor,
  uuid,
  avatarUrlFunc,
  ...flexProps
}: UserWithAvatarProps) => {
  if (isLoading) {
    return (
      <Flex alignItems="center" {...flexProps}>
        <Skeleton height={24} width={150} />
      </Flex>
    )
  }

  const pathToUrlHandler = usePathWithBaseUrl ? pathToUrlWithBaseUrl : pathToUrl
  const linkTarget = usePathWithBaseUrl ? '_blank' : undefined

  const nameString = full_name || name

  if (!nameString || !isString(nameString)) {
    return <>-</>
  }

  const initials = getInitials(nameString)

  const isTerminated = status === IdStatuses.terminated
  const displayName = nameString + (isTerminated ? ' · Deactivated' : '')

  const avatarJSX = (
    <Box mr={size && size >= 40 ? 's-16' : 's-8'}>
      <UserAvatar
        avatar={avatar}
        full_name={full_name}
        name={name}
        size={size}
        status={status}
        uuid={uuid}
        badgeProps={badgeProps}
        progress={progress}
        progressColor={progressColor}
      />
    </Box>
  )
  const Container = asText ? AvatarAsTextFlexContainer : Flex
  const link = getLocationDescriptor(
    id && avatarUrlFunc
      ? avatarUrlFunc(String(id))
      : pathToUrlHandler(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }),
  )

  const renderCompact = () => {
    if (disableTooltip) {
      return avatarJSX
    }
    return (
      <TooltipAvatar placement={tooltipPlacement} text={displayName}>
        <TooltipLink to={link} target={linkTarget}>
          {avatarJSX}
        </TooltipLink>
      </TooltipAvatar>
    )
  }

  return (
    <Container alignItems="center" data-testid={`avatar-${initials}`} {...flexProps}>
      {compact ? (
        renderCompact()
      ) : (
        <>
          {avatarJSX}
          {id && !disableLink ? (
            <UserLink
              {...flexProps}
              id={id}
              name={name}
              full_name={full_name}
              isLoading={isLoading}
              status={status}
              usePathWithBaseUrl={usePathWithBaseUrl}
              avatarUrlFunc={avatarUrlFunc}
            />
          ) : (
            <UserName name={name} full_name={full_name} status={status} />
          )}
        </>
      )}
    </Container>
  )
}

export default UserWithAvatar
