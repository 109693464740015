import { utcToZonedTime } from 'date-fns-tz'
import { LargeWeeklyCalendarEventInterface } from '@components/LargeWeeklyCalendar'
import { Timezone } from '@src/interfaces/selectors'

export function adaptTimezone(
  timeSlots: LargeWeeklyCalendarEventInterface[],
  timezone?: Timezone,
): LargeWeeklyCalendarEventInterface[]
export function adaptTimezone(
  timeSlot: LargeWeeklyCalendarEventInterface,
  timezone?: Timezone,
): LargeWeeklyCalendarEventInterface
export function adaptTimezone(
  data: LargeWeeklyCalendarEventInterface | LargeWeeklyCalendarEventInterface[],
  timezone?: Timezone,
): LargeWeeklyCalendarEventInterface | LargeWeeklyCalendarEventInterface[] {
  if (Array.isArray(data)) {
    return data.map(timeSlot => adapt(timeSlot, timezone))
  }

  return adapt(data, timezone)
}

const adapt = (
  timeSlot: LargeWeeklyCalendarEventInterface,
  timezone?: Timezone,
): LargeWeeklyCalendarEventInterface => ({
  ...timeSlot,
  start: timezone?.id
    ? utcToZonedTime(timeSlot.start as string, timezone.id)
    : timeSlot.start,
  end: timezone?.id ? utcToZonedTime(timeSlot.end as string, timezone.id) : timeSlot.end,
})
