import React from 'react'
import { Box, MoreBar, Subheader } from '@revolut/ui-kit'
import { useGetJobPostingRequisitions } from '@src/api/requisitions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { RequisitionTable } from './RequisitionTable'
import { RequisitionInterface } from '@src/interfaces/requisitions'

interface ConnectedRequisitionsProps {
  requisitions: RequisitionInterface[]
  onCreateNewRequisition: VoidFunction
}

export const ConnectedRequisitions = ({
  requisitions,
  onCreateNewRequisition,
}: ConnectedRequisitionsProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data, isLoading } = useGetJobPostingRequisitions(values.id)
  return (
    <Box>
      <Subheader>
        <Subheader.Title>Connect to requisition</Subheader.Title>
      </Subheader>
      <Subheader variant="nested">
        <Subheader.Title>
          Requisitions is a request for a new employee to fill a vacant position in an
          organisation. You can select existing or create a new one.
        </Subheader.Title>
      </Subheader>
      <MoreBar>
        <MoreBar.Action useIcon="Plus" onClick={onCreateNewRequisition}>
          Create new
        </MoreBar.Action>
      </MoreBar>
      <RequisitionTable
        requisitions={[...requisitions, ...(data?.results || [])]}
        loading={isLoading}
      />
    </Box>
  )
}
