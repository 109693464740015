import React from 'react'
import { Button, Popup } from '@revolut/ui-kit'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { useMeetingEvents } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/SchedulePopup/useMeetingEvents'
import { PopupBody } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/SchedulePopup/PopupBody'
import { useMeetingTemplate } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/hooks/useMeetingTemplate'

interface Props {
  isOpen: boolean
  meeting: OneToOneMeeting
  handleClose: VoidFunction
}

export const SchedulePopup = ({ isOpen, meeting, handleClose }: Props) => {
  const { events, isLoading: isEventsLoading } = useMeetingEvents(meeting)
  const { template, isLoading: isTemplateLoading } = useMeetingTemplate()

  return (
    <Popup size="sm" open={isOpen}>
      <PopupBody
        meeting={meeting}
        events={events}
        template={template}
        isLoading={isEventsLoading || isTemplateLoading}
      />
      <Popup.Actions>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
