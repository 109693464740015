import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  StatusPopup,
  Subheader,
  Text,
  Tooltip,
  VStack,
  useStatusPopup,
  useTooltip,
} from '@revolut/ui-kit'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { JobPostingInterface, LocationSource } from '@src/interfaces/jobPosting'
import { LocationInterface, RequisitionInterface } from '@src/interfaces/requisitions'
import { PageBody } from '@src/components/Page/PageBody'
import { selectorKeys } from '@src/constants/api'
import {
  patchJobDescriptionOfRequisition,
  useGetHiringEnabledLocations,
  useGetRequisition,
} from '@src/api/requisitions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useLocation, useParams } from 'react-router-dom'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import NewMultiSelect from '@src/components/Inputs/NewMultiSelect/NewMultiSelect'
import { PageActions } from '@src/components/Page/PageActions'
import { DetailsSidebars, getBackUrl } from '@src/features/JobPostingFlow/utils'
import { navigateTo } from '@src/actions/RouterActions'
import PostingCompensationBand from '@src/components/LocationCompensationBand/PostingCompensationBand'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useGetPostingCompensationBands } from '@src/api/benchmarks'
import ManualCompensationBandForm from '@src/pages/Forms/JobPosting/Components/ManualCompensationBandForm'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '../Form/Buttons/NewSaveButtonWithPopup'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { ConnectedRequisitions } from '@src/features/JobPostingFlow/components/ConnectedRequistions'
import { CreateNewRequisitionSidebar } from '@src/features/JobPostingFlow/components/CreateNewRequisitionSidebar'
import { getErrorMessageFromError } from '@src/store/notifications/actions'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

const locationsToLocationOptions = (locations?: LocationInterface[]) => {
  return (locations || []).map(location => ({
    label: location.name,
    value: location,
  }))
}

type HiringLocationsProps = {
  requisitionLocations?: LocationInterface[]
}

const HiringLocations = ({ requisitionLocations }: HiringLocationsProps) => {
  const { values, errors } = useLapeContext<JobPostingInterface>()
  const { data: enabledLocationsData } = useGetHiringEnabledLocations()
  const locationOptions = locationsToLocationOptions(enabledLocationsData)
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const enableAutomaticCompensationBands =
    jobPostingSettings?.enable_automatic_compensation_ranges
  const selectedLocationIds = (values?.locations || []).map(
    ({ id: locationId }) => locationId,
  )
  const mandatoryCompensationLocations = (
    jobPostingSettings?.mandatory_compensation_locations || []
  )
    .map(({ id: locationId }) => locationId)
    .filter(locationId => selectedLocationIds?.includes(locationId))
  const { data: compensationBands, error: compensationBandsError } =
    useGetPostingCompensationBands(
      values.id,
      values.locations
        ?.filter(
          item =>
            item.posting_compensation_enabled ||
            mandatoryCompensationLocations.includes(item.id),
        )
        ?.map(item => item.id),
    )

  return (
    <VStack gap="s-16">
      <Checkbox
        checked={values.location_source === LocationSource.requisition}
        onChange={event => {
          const fromRequisitions = event.currentTarget.checked
          if (fromRequisitions) {
            values.location_source = LocationSource.requisition
            values.locations = undefined
          } else {
            values.location_source = LocationSource.manual
          }
        }}
      >
        <Text>Autofill locations from Requisitions</Text>
      </Checkbox>
      {values.location_source === LocationSource.requisition ? (
        <NewMultiSelect
          name="locations"
          placeholder="Locations"
          required
          options={locationOptions}
          value={locationsToLocationOptions(requisitionLocations)}
          disabled
        />
      ) : (
        <>
          <LapeNewMultiSelect<LocationInterface>
            name="locations"
            placeholder="Locations"
            required
            options={locationOptions}
            onAfterChange={locationValues => {
              const entries = Object.entries(values.salary_bands_by_location || {})
              const locationKeys = (locationValues || []).map(({ label }) => label)
              const keepEntries = entries.filter(([key]) => locationKeys.includes(key))
              if (keepEntries.length) {
                values.salary_bands_by_location = Object.fromEntries(keepEntries)
              }
            }}
          />
          <PostingCompensationBand bands={compensationBands} />
        </>
      )}
      {!enableAutomaticCompensationBands && !!mandatoryCompensationLocations.length && (
        <>
          {values.location_source !== LocationSource.manual && (
            <PostingCompensationBand bands={compensationBands} />
          )}
          <ManualCompensationBandForm
            locationIds={mandatoryCompensationLocations}
            values={values.salary_bands_by_location}
            errors={errors.salary_bands_by_location}
            onChange={salaryBandsByLocation => {
              values.salary_bands_by_location = values.salary_bands_by_location
                ? {
                    ...values.salary_bands_by_location,
                    ...salaryBandsByLocation,
                  }
                : salaryBandsByLocation
            }}
          />
        </>
      )}
      {compensationBandsError && (
        <ActionWidget
          title="Could not show compensation bands"
          text={getErrorMessageFromError(compensationBandsError)}
        />
      )}
    </VStack>
  )
}

type DetailsProps = {
  onAfterSubmit: () => void
}

export const useSidebars = () => {
  const [state, setState] = useState<DetailsSidebars>()
  return {
    sidebar: state,
    toggleSidebar: (sidebar: DetailsSidebars) => {
      setState(sidebar === state ? undefined : sidebar)
    },
    closeSidebar: () => {
      setState(undefined)
    },
  }
}

export const Details = ({ onAfterSubmit }: DetailsProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [requisitions, setRequisitions] = useState<RequisitionInterface[]>([])
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const { data: requisition } = useGetRequisition(locationState?.requisitionId)
  const tooltip = useTooltip()
  const statusPopup = useStatusPopup()
  const { sidebar, toggleSidebar, closeSidebar } = useSidebars()
  const handleRequisitionConnectionError = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>
          Cannot connect job posting to requisition(s)
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button elevated onClick={statusPopup.hide}>
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const connectedRequisitions = requisition
    ? [requisition, ...requisitions]
    : requisitions
  return (
    <>
      <PageBody>
        <Box>
          <Subheader>
            <Subheader.Title>Job post name</Subheader.Title>
          </Subheader>
          <LapeNewInput
            name="name"
            label="Job posting title"
            message="This is job posting title candidates will see on the career website (Specialisation name by default)"
            required
          />
        </Box>
        <ConnectedRequisitions
          requisitions={connectedRequisitions}
          onCreateNewRequisition={() => {
            toggleSidebar('createNewRequisition')
          }}
        />
        <Box>
          <Subheader>
            <Subheader.Title>Locations</Subheader.Title>
          </Subheader>
          <HiringLocations
            requisitionLocations={(connectedRequisitions || [])
              .flatMap(({ locations }) => locations)
              .filter(Boolean)}
          />
        </Box>
        <Box>
          <Subheader>
            <Subheader.Title>Specialisation</Subheader.Title>
          </Subheader>
          <Box {...tooltip.getAnchorProps()}>
            <LapeRadioSelectInput
              name="specialisation"
              label="Specialisation"
              selector={selectorKeys.specialisations}
              onAfterChange={() => {
                if (values.specialisation?.name) {
                  values.name = values.specialisation.name
                }
                if (values.hiring_process) {
                  values.hiring_process = undefined
                }
              }}
              // Once a Job Posting is created, the “Specialisation” field
              // should not be editable in order to prevent situations
              // when candidate’s job posting and candidate specialisation’s
              // hiring process are different from each other.
              disabled={!!values.id}
            />
            {!!values.id && (
              <Tooltip {...tooltip.getTargetProps()}>
                It's not possible to change job posting's specialisation after the job
                posting is created
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box>
          <Subheader>
            <Subheader.Title>Hiring team</Subheader.Title>
          </Subheader>
          <VStack gap="s-16">
            <LapeRadioSelectInput
              name="recruiter"
              label="Recruiter"
              selector={selectorKeys.employee}
            />
            <HideIfCommercial>
              <LapeRadioSelectInput
                name="coordinator"
                label="Coordinator"
                required={false}
                selector={selectorKeys.employee}
                clearable
              />
            </HideIfCommercial>
          </VStack>
        </Box>
      </PageBody>
      <PageActions>
        <Button
          onClick={() => {
            navigateTo(getBackUrl(params, locationState), locationState)
          }}
          variant="secondary"
          elevated
        >
          Cancel
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          noPopup
          hideWhenNoChanges={false}
          onAfterSubmit={async resp => {
            onAfterSubmit()
            let hasError = false
            if (connectedRequisitions) {
              try {
                await Promise.all(
                  connectedRequisitions.map(connectedRequisition =>
                    patchJobDescriptionOfRequisition(connectedRequisition.id, {
                      job_posting: {
                        id: resp.id,
                      },
                    }),
                  ),
                )
              } catch {
                hasError = true
                handleRequisitionConnectionError()
              }
            }
            if (!hasError) {
              navigateTo(
                pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params),
                {
                  ...locationState,
                  id: resp.id,
                },
              )
            }
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
      {sidebar === 'createNewRequisition' && (
        <CreateNewRequisitionSidebar
          onClose={closeSidebar}
          onCreateNewRequisition={newRequisition => {
            if (!values.specialisation && newRequisition.specialisation) {
              values.specialisation = {
                id: newRequisition.specialisation.id,
              }
            }
            if (!values.recruiter && newRequisition.recruiter) {
              values.recruiter = newRequisition.recruiter
            }
            setRequisitions([...requisitions, newRequisition])
            closeSidebar()
          }}
        />
      )}
    </>
  )
}
