import {
  ActionButton,
  Icon,
  InputGroup,
  Banner,
  Text,
  Input,
  RadioSelect,
  SelectOptionItemType,
  HStack,
} from '@revolut/ui-kit'
import { useSalesforceSearch, useTestQueryData } from '@src/api/kpis'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiInterface, SalesforceSearchInterface } from '@src/interfaces/kpis'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { debounce } from 'lodash'
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { FetchColumn } from '../SidebarForms/FetchColumn'
import { UnitOfMeasure } from '../SidebarForms/components/UnitOfMeasure'
import { ProgressDirection } from '../SidebarForms/components/ProgressDirection'
import { InitTargetValues } from '../SidebarForms/components/InitTargetValues'
import { QueryTestingTable } from '@src/features/FormTabs/Kpi/QueryTestingTable'
import Tooltip from '@src/components/Tooltip/Tooltip'

export const SalesforceForm = () => {
  const [searchInput, setSearchInput] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const inputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const { values } = useLapeContext<KpiInterface>()
  const {
    data: searchData,
    isError,
    error,
    isFetching,
  } = useSalesforceSearch({ reportName: searchValue })

  const {
    mutate: fetchPreviewData,
    data: previewData,
    isLoading: previewPending,
    isError: isPreviewError,
    error: previewError,
  } = useTestQueryData()

  const options =
    searchData?.map(({ id, name, url }) => ({
      id,
      label: name,
      key: id,
      value: { url, name, id },
    })) || []

  const searchDebounced = useCallback(
    debounce((input: string) => {
      setSearchValue(input)
      setIsLoading(false)
    }, 1000),
    [],
  )

  useEffect(() => {
    // to initialize it to the report name
    if (values.salesforce_report && values.salesforce_report.name !== searchInput) {
      setSearchInput(values.salesforce_report.name)
    }
  }, [values])

  const onSelectionChange = (selection: SalesforceSearchInterface | null) => {
    if (selection) {
      values.salesforce_report = selection
      setSearchInput(selection.name)
    }
  }

  const onSearch = (input?: string) => {
    setIsLoading(true)
    setSearchInput(input || '')
    searchDebounced(input || '')
  }

  return (
    <>
      <InputGroup>
        <HStack gap="s-8" align="center">
          <Input
            invalid={!!isError}
            errorMessage={error}
            containerRef={inputRef}
            placeholder="Search for report"
            pending={isFetching || isLoading}
            onChange={(ev: FormEvent<HTMLInputElement>) =>
              onSearch(ev?.currentTarget?.value)
            }
            renderAction={() =>
              isFetching || isLoading ? undefined : <Icon name="Search" />
            }
            value={searchInput}
          />
          <RadioSelect<
            SalesforceSearchInterface,
            SelectOptionItemType<SalesforceSearchInterface>
          >
            labelList="Reports"
            value={undefined}
            open={!!searchValue && !isFetching && !isLoading}
            anchorRef={inputRef}
            onClose={() => setSearchValue('')}
            options={options}
            fitInAnchor
            labelNoResults="No reports found"
            labelClear="Cancel"
            type="confirm"
            labelApply="Select report"
            onChange={onSelectionChange}
          >
            {option => <Text key={option.key}>{option.label}</Text>}
          </RadioSelect>
          <Tooltip placement="top" text="Ensure the Report is public">
            <Icon name="Info" />
          </Tooltip>
        </HStack>
        {values.salesforce_report?.url && (
          <>
            <FetchColumn type="salesforce" variant="date" />
            <FetchColumn type="salesforce" variant="target" />
            <UnitOfMeasure />
            <ProgressDirection />
            <InputGroup variant="horizontal">
              <InitTargetValues />
            </InputGroup>
          </>
        )}
        <ActionButton
          onClick={() => {
            fetchPreviewData({
              query_db: 'salesforce',
              salesforce_report_url: values.salesforce_report?.url,
            })
          }}
          disabled={!values.salesforce_report?.url}
          useIcon={previewPending ? undefined : <Icon name="Play" />}
          pending={previewPending}
        >
          Preview data
        </ActionButton>
      </InputGroup>
      {isPreviewError && (
        <Banner>
          <Banner.Content>
            <Banner.Title>Couldn't fetch preview</Banner.Title>
            <Banner.Description>
              {getStringMessageFromError(previewError)}
            </Banner.Description>
          </Banner.Content>
        </Banner>
      )}
      {previewData && !isPreviewError && (
        <QueryTestingTable response={previewData.data} />
      )}
    </>
  )
}
