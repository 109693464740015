import React from 'react'
import { ColumnInterface, RowInterface } from '@src/interfaces/data'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  employeeColumn,
  recurrenceColumn,
  relationColumn,
  statusColumn,
  timeColumn,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/constants/columns'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { oneToOneModel } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/models/oneToOneModel'
import Table from '@components/TableV2/Table'

export const getRow = (employee: EmployeeInterface): RowInterface<OneToOneMeeting> => ({
  cells: [
    { ...getEmployeeColumn(employee), width: 200 },
    { ...relationColumn, width: 200 },
    { ...timeColumn, width: 200 },
    { ...recurrenceColumn, width: 100 },
    { ...statusColumn, width: 100 },
  ],
})

export const getEmployeeColumn = (
  employee: EmployeeInterface,
): ColumnInterface<OneToOneMeeting> => {
  return {
    ...employeeColumn,
    dynamicHyperlinks: meeting =>
      pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
        id: oneToOneModel.getDependantAttendee(employee, meeting).id,
      }),
    insert: ({ data: meeting }) => {
      return (
        <Table.EmployeeCell
          employee={oneToOneModel.getDependantAttendee(employee, meeting)}
        />
      )
    },
  }
}
