import { ActionButton } from '@revolut/ui-kit'
import React, { useCallback, useEffect } from 'react'
import {
  useCreateMergeConnection,
  useActivateMergeConnection,
} from '@src/api/integrations'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { MergeIntegration } from '@src/interfaces/integrations'

const MERGE_API_URL = 'https://api-eu.merge.dev'

interface ConnectMergeIntegrationButtonProps {
  integration: MergeIntegration
  onSuccess?: () => void
}

export const ConnectMergeIntegrationButton = ({
  integration,
  onSuccess,
}: ConnectMergeIntegrationButtonProps) => {
  const showStatusPopup = useShowStatusPopup()
  const {
    mutateAsync: createConnection,
    data,
    isLoading: isCreatingConnection,
    isSuccess,
  } = useCreateMergeConnection()
  const { mutateAsync: activateConnection } = useActivateMergeConnection(data?.data.id)

  const onConnectSuccess = useCallback(
    async public_token => {
      try {
        await activateConnection([undefined, { public_token }])
        onSuccess?.()
      } catch (err) {
        showStatusPopup({
          status: 'error',
          title: 'Failed to activate connection',
          description: getStringMessageFromError(
            err,
            'Something went wrong. Please try again.',
          ),
        })
      }
    },
    [onSuccess, showStatusPopup],
  )

  const { open, isReady } = useMergeLink({
    linkToken: data?.data.token,
    onSuccess: onConnectSuccess,
    tenantConfig: {
      apiBaseURL: MERGE_API_URL,
    },
  })

  useEffect(() => {
    if (isSuccess && isReady) {
      open()
    }
  }, [isSuccess, isReady])

  const onConnect = async () => {
    try {
      await createConnection({ integration_type: integration })
    } catch (err) {
      showStatusPopup({
        status: 'error',
        title: 'Failed to enable',
        description: getStringMessageFromError(
          err,
          'Something went wrong. Please try again.',
        ),
      })
    }
  }

  return (
    <ActionButton
      useIcon="Link"
      pending={isCreatingConnection}
      disabled={isCreatingConnection}
      variant="accent"
      onClick={e => {
        e.preventDefault()
        return onConnect()
      }}
    >
      Enable
    </ActionButton>
  )
}
