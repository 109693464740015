import { useCallback, useEffect, useState } from 'react'
import { Timezone } from '@src/interfaces/selectors'
import { OneToOneMeetingTemplate } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/hooks/useMeetingTemplate'
import { useParams } from 'react-router-dom'
import { addMinutes, roundToNearestMinutes } from 'date-fns'
import {
  calendarEventTextColor,
  highlightedEventBackgroundColor,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ScheduleOneToOne/constants'
import {
  LargeWeeklyCalendarChangeEventInterface,
  LargeWeeklyCalendarEventInterface,
} from '@components/LargeWeeklyCalendar'
import { getDateFromEventDate } from '@components/LargeWeeklyCalendar/LargeWeeklyCalendar'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { adaptTimezone } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/adaptors/timezone'

interface EventScheduler {
  event?: LargeWeeklyCalendarEventInterface
  updateEvent: (params: LargeWeeklyCalendarChangeEventInterface) => void
}

export const useScheduleEvent = (
  meeting: OneToOneMeeting,
  template: OneToOneMeetingTemplate,
  timezone?: Timezone,
): EventScheduler => {
  const { eventId } = useParams<{ eventId?: string }>()
  const [event, setEvent] = useState<LargeWeeklyCalendarEventInterface>()
  const [startDate, setStartDate] = useState<Date>(
    roundToNearestMinutes(new Date(), { nearestTo: 15 }),
  )
  const [endDate, setEndDate] = useState<Date>(
    addMinutes(new Date(startDate), template.duration),
  )

  useEffect(() => {
    if (eventId) {
      setEvent(undefined)
      return
    }

    setEvent(
      adaptTimezone(
        {
          start: startDate,
          end: endDate,
          title: `1:1 with ${meeting.employee.full_name}`,
          color: calendarEventTextColor,
          backgroundColor: highlightedEventBackgroundColor,
        },
        timezone,
      ),
    )
  }, [eventId, startDate.toISOString(), endDate.toISOString(), timezone?.id])

  const updateEvent = useCallback(
    ({ event: selectedEvent, changes }: LargeWeeklyCalendarChangeEventInterface) => {
      if (selectedEvent.isReadOnly) {
        return
      }

      if (changes.start) {
        setStartDate(prevState => getDateFromEventDate(changes.start) ?? prevState)
      }

      if (changes.end) {
        setEndDate(prevState => getDateFromEventDate(changes.end) ?? prevState)
      }
    },
    [setStartDate, setEndDate],
  )

  return {
    event,
    updateEvent,
  }
}
