import * as React from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import MultiInput, { MultiInputProps } from '@components/Inputs/MultiInput/MultiInput'
import { useFormValidator } from '@src/features/Form/FormValidator'

interface Props<T, V> extends MultiInputProps<T> {
  name: string
  /** Marks field as optional otherwise */
  required?: boolean
  /** Hides the optional text for display only fields */
  hideOptional?: boolean
  mapValue?: (value: V) => T
}

const LapeMultiInput = <T extends {} = { text: string }, V = unknown>({
  name,
  hideOptional,
  mapValue,
  ...props
}: Props<T, V>) => {
  const lapeProps = useLapeField(name)
  const { value, onChange, hidden } = lapeProps
  const formValidator = useFormValidator()

  if (hidden) {
    return null
  }

  const commonProps = getCommonInputProps(
    { ...props, hideOptional, name },
    lapeProps,
    !!formValidator?.validated,
  )

  const isErrorsObject = typeof lapeProps.error === 'object'

  return (
    <MultiInput<T>
      onChange={onChange}
      valueFieldName="text"
      value={mapValue?.(value) || value}
      {...props}
      {...commonProps}
      message={isErrorsObject ? undefined : commonProps.message}
      inputErrors={isErrorsObject ? lapeProps.error : undefined}
    />
  )
}

export default connect(LapeMultiInput)
