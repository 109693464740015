import React from 'react'
import { Widget, Text, VStack, HStack, Bullet, Token, Link } from '@revolut/ui-kit'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { REVOLUT_PEOPLE_OKTA_DOCUMENTATION } from '@src/constants/externalLinks'

export const OktaIntegration = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Okta"
        subtitle="Collaboration"
        backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
      >
        {/* TODO: https://revolut.atlassian.net/browse/REVCOR-3316 add link after getting Okta's approval  */}
        {/* <ActionButton
          use="a"
          target="_blank"
          useIcon="LinkExternal"
        >
          Manage in Okta
        </ActionButton> */}
      </PageHeader>

      <PageBody mb="s-64" mt="s-16">
        <Widget p="s-16">
          <Text variant="h5" display="block" mb="s-16">
            About integration
          </Text>

          <Text variant="caption" display="block">
            Okta is an identity and access management solution that links all your apps,
            logins and devices into one. Revolut People’s Okta integration supports single
            sign-on.
          </Text>

          <Text variant="h5" display="block" my="s-16">
            How to use
          </Text>

          <VStack gap="s-16" mt="s-24">
            <HStack gap="s-16">
              <Bullet variant="filled">1</Bullet>
              <Text color={Token.color.greyTone50}>
                Please note that only a Workspace Owner can configure SSO for a Revolut
                People workspace
              </Text>
            </HStack>

            <HStack gap="s-16">
              <Bullet variant="filled">2</Bullet>
              <Text color={Token.color.greyTone50}>
                To learn more about the prerequisites and to set up provisioning with
                Okta, follow our{' '}
                <Link
                  use="a"
                  rel="noreferrer noopener"
                  target="_blank"
                  href={REVOLUT_PEOPLE_OKTA_DOCUMENTATION}
                >
                  SSO guide
                </Link>
              </Text>
            </HStack>

            <HStack gap="s-16">
              <Bullet variant="filled">3</Bullet>
              <Text color={Token.color.greyTone50}>
                After integration is connected, you can enable Okta authentication in{' '}
                <Link use={InternalLink} to={ROUTES.SETTINGS.SECURITY}>
                  Security settings
                </Link>
              </Text>
            </HStack>
          </VStack>
        </Widget>
      </PageBody>
    </PageWrapper>
  )
}
