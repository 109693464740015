import React from 'react'
import { Icon, MoreBar } from '@revolut/ui-kit'

export const HeaderActions = () => {
  return (
    <MoreBar maxCount={2}>
      <MoreBar.Action use="button" useIcon={<Icon size={16} name="Calendar" />}>
        Change Schedule
      </MoreBar.Action>
      <MoreBar.Action use="button" useIcon={<Icon size={16} name="Gear" />}>
        Settings
      </MoreBar.Action>
    </MoreBar>
  )
}
