import * as React from 'react'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { ScheduleOneToOneCalendar } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ScheduleOneToOne/ScheduleOneToOneCalendar'
import { Button, Box, Token, useToggle } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { goBack } from '@src/actions/RouterActions'
import { PageActions } from '@components/Page/PageActions'
import { PageBody } from '@components/Page/PageBody'
import { useCallback, useState } from 'react'
import { ScheduleOneToOneMeetingPayload, useGetEmployeeMeeting } from '@src/api/meetings'
import PageLoading from '@components/PageLoading/PageLoading'
import { LargeWeeklyCalendarEventInterface } from '@components/LargeWeeklyCalendar'
import { SchedulePopup } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/SchedulePage/SchedulePopup'
import { getDateFromEventDate } from '@components/LargeWeeklyCalendar/LargeWeeklyCalendar'
import { buildRRule } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/adaptors/rrule'
import { meetingRecurrence } from '@src/interfaces/meetingsTracker'
import { useMeetingTemplate } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/hooks/useMeetingTemplate'
import { zonedTimeToUtc } from 'date-fns-tz'
import { Timezone } from '@src/interfaces/selectors'

export const ScheduleOneToOnePage = () => {
  const { employeeId, meetingId, eventId } =
    useParams<{ employeeId: string; meetingId: string; eventId?: string }>()
  const [schedulePopupOpen, schedulePopupToggler] = useToggle()
  const { data: meeting, isLoading: isMeetingLoading } = useGetEmployeeMeeting(
    employeeId,
    meetingId,
  )
  const { template, isLoading: isTemplateLoading } = useMeetingTemplate()

  const [selectedEvent, setSelectedEvent] = useState<ScheduleOneToOneMeetingPayload>()

  const backUrl = pathToUrl(
    ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.OVERVIEW,
    { id: employeeId },
  )
  const handleGoBack = () => goBack(backUrl)

  const handleSelectEvent = useCallback(
    (
      event: LargeWeeklyCalendarEventInterface | undefined,
      recurrence: meetingRecurrence,
      timezone: Timezone,
    ) => {
      const startDate = getDateFromEventDate(event?.start)
      const endDate = getDateFromEventDate(event?.end)

      if (!event || !startDate || !endDate) {
        return
      }

      const start = zonedTimeToUtc(startDate, timezone.id).toISOString()
      const end = zonedTimeToUtc(endDate, timezone.id).toISOString()
      const title = String(event.title)
      const rrule = buildRRule(recurrence, start)

      setSelectedEvent({
        start,
        end,
        recurrence: [rrule],
        title,
      })
    },
    [setSelectedEvent],
  )

  if (isMeetingLoading || isTemplateLoading) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Schedule recurring 1:1 meeting"
        subtitle="You are scheduling a recurring event. Pick the time slot and frequency below."
        backUrl={backUrl}
      />
      {meeting && template && (
        <>
          <PageBody maxWidthMd={Token.breakpoint.xxl}>
            <Box width="100%" height="75vh">
              <ScheduleOneToOneCalendar
                meeting={meeting}
                template={template}
                handleSelectEvent={handleSelectEvent}
              />
            </Box>
          </PageBody>
          <PageActions maxWidthMd={Token.breakpoint.xxl}>
            <Button variant="secondary" onClick={handleGoBack}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!selectedEvent}
              onClick={schedulePopupToggler.on}
            >
              Schedule
            </Button>
          </PageActions>
        </>
      )}
      {meeting && selectedEvent && (
        <SchedulePopup
          isOpen={schedulePopupOpen}
          title={eventId ? 'Use selected series' : 'Send event invite'}
          description={
            !eventId
              ? `We’ll send a Google event invite to ${meeting.employee.full_name}`
              : `We’ll use this meeting series for 1:1s with ${meeting.employee.full_name}`
          }
          meeting={meeting}
          event={selectedEvent}
          handleClose={schedulePopupToggler.off}
          handleGoBack={handleGoBack}
        />
      )}
    </PageWrapper>
  )
}
