import React, { useMemo } from 'react'
import { FilterButton, MoreBar } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import Table from '@src/components/TableV2/Table'
import { ROUTES } from '@src/constants/routes'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { MeetingTemplatesQueryKeys, templateRequests } from '@src/api/meetings'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { TableNames } from '@src/constants/table'
import { MeetingTemplateInterface } from '@src/interfaces/meetings'
import {
  templateAudienceColumn,
  templateNameColumn,
  templateDurationColumn,
  templateFrequencyColumn,
  templateCreatedByColumn,
  templateInputFieldsColumn,
} from '../constants/columns'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { navigateTo } from '@src/actions/RouterActions'

const initialSortBy = [
  {
    sortBy: MeetingTemplatesQueryKeys.TemplateName,
    direction: SORT_DIRECTION.DESC,
  },
]

const ROW: RowInterface<MeetingTemplateInterface> = {
  cells: [
    {
      ...templateNameColumn,
      width: 400,
    },
    {
      ...templateAudienceColumn,
      width: 200,
    },
    {
      ...templateDurationColumn,
      width: 200,
    },
    {
      ...templateFrequencyColumn,
      width: 200,
    },
    {
      ...templateCreatedByColumn,
      width: 200,
    },
    { ...templateInputFieldsColumn, width: 200 },
  ],
}

export const MeetingTemplates = () => {
  const user = useSelector(selectUser)

  const table = useTable(templateRequests, undefined, initialSortBy)
  const isActiveFilterByCurrentUser = useMemo(() => {
    return Boolean(
      table.filterBy
        .find(filter => filter.columnName === MeetingTemplatesQueryKeys.CreatedBy)
        ?.filters.find(({ id }) => Number(id) === user.id),
    )
  }, [table.filterBy, user.id])

  const onSwitchMissingStatus = () => {
    table.onFilterChange([
      {
        columnName: MeetingTemplatesQueryKeys.CreatedBy,
        filters: isActiveFilterByCurrentUser
          ? []
          : [{ id: user.id.toString(), name: user.id.toString() }],
      },
    ])
  }
  return (
    <PageWrapper>
      <PageHeader
        title="Manage templates"
        backUrl={ROUTES.PERFORMANCE.ONE_TO_ONE.OVERVIEW}
      />
      <Table.Widget>
        <Table.Widget.Filters>
          <Table.Search
            placeholder="Search by employee name"
            onFilter={table.onFilterChange}
            variant="compact"
          />
          <FilterButton
            active={isActiveFilterByCurrentUser}
            onClick={onSwitchMissingStatus}
          >
            My templates
          </FilterButton>
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar maxCount={2}>
            <MoreBar.Action
              variant="accent"
              to={pathToUrl(ROUTES.FORMS.MEETING_TEMPLATE.EDIT, { step: 'general' })}
              use={InternalLink}
              useIcon="Plus"
            >
              Add Template
            </MoreBar.Action>
            <Table.ColumnsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable<MeetingTemplateInterface>
            onRowClick={(row: MeetingTemplateInterface) => {
              navigateTo(
                pathToUrl(ROUTES.FORMS.MEETING_TEMPLATE.PREVIEW, {
                  id: row.id,
                }),
              )
            }}
            name={TableNames.MeetingTemplates}
            useWindowScroll
            row={ROW}
            {...table}
            emptyState={<EmptyTableRaw title="There are no templates" />}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </PageWrapper>
  )
}
