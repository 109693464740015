import React, { useState } from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { LargeWeeklyCalendar } from '@components/LargeWeeklyCalendar'
import { Box, Flex } from '@revolut/ui-kit'
import { getCurrentWeek, NavigationWeek } from '@components/NavigateWeek/NavigateWeek'
import { useCalendarEvents } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ReviewEvents/useCalendarEvents'
import { Legend } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ReviewEvents/Legend'

interface Props {
  employee: EmployeeInterface
}

export const ReviewEventsCalendar = ({ employee }: Props) => {
  const [week, setWeek] = useState<NavigationWeek>(getCurrentWeek())
  const { events } = useCalendarEvents(employee, week)

  return (
    <Box px="s-16">
      <Box height="50vh" width="100%" overflow="hidden">
        <LargeWeeklyCalendar
          startDayOfWeek={1}
          eventView={['time']}
          events={events}
          workweek
          disabled
          onSwitchWeek={(start, end) => {
            setWeek({
              start: start.toISOString(),
              end: end.toISOString(),
            })
          }}
        />
      </Box>
      <Flex my="s-16" justifyContent="space-around">
        <Legend />
      </Flex>
    </Box>
  )
}
