import { BottomSheet, Button, Flex, Header, Input, ActionButton } from '@revolut/ui-kit'
import produce from 'immer'
import React, { useState } from 'react'

interface Props {
  close: VoidFunction
  talkingPoints: string[]
  onSavePoints: (points: string[]) => void
}

const initializePoints = (talkingPoints: string[]) => {
  return talkingPoints?.length > 0 ? talkingPoints : ['']
}

export const TalkingPointsBottomSheetContent = ({
  close,
  talkingPoints,
  onSavePoints,
}: Props) => {
  const [points, setPoints] = useState(() => initializePoints(talkingPoints))

  const filteredPoints = points.filter(Boolean)

  const handlePointChange = (index: number, newValue: string) => {
    setPoints(prevPoints =>
      produce(prevPoints, draft => {
        draft[index] = newValue
      }),
    )
  }

  const handlePointAdd = () => {
    setPoints(prevPoints => [...prevPoints, ''])
  }

  const handlePointDelete = (index: number) => {
    setPoints(prevPoints => prevPoints.filter((_, i) => i !== index))
  }

  const handlePointSave = () => {
    onSavePoints(filteredPoints)
    close()
  }

  return (
    <>
      <Header>
        <Header.Title>Add Talking points</Header.Title>
      </Header>
      <BottomSheet.Description>
        <Flex gap="s-8" flexDirection="column">
          {points.map((pointText, index) => (
            <Flex alignItems="center" gap="s-8" key={index}>
              <Input
                label="Talking point title"
                value={pointText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handlePointChange(index, event.target.value)
                }
              />
              {points.length > 1 && (
                <ActionButton
                  onClick={() => handlePointDelete(index)}
                  variant="black"
                  useIcon="Minus"
                />
              )}
            </Flex>
          ))}
          <ActionButton onClick={handlePointAdd} mt="s-8" variant="black" useIcon="Plus">
            Add
          </ActionButton>
        </Flex>
      </BottomSheet.Description>
      <BottomSheet.Actions>
        <Button onClick={handlePointSave} variant="primary">
          Save
        </Button>
        <Button variant="black" onClick={close}>
          Close
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
