import React from 'react'
import { Box, Text, Token, Widget } from '@revolut/ui-kit'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  createRequisitionTitleColumn,
  requisitionHeadcountColumn,
} from '@src/constants/columns/requisition'
import { seniorityNameRequisitionsColumn } from '@src/constants/columns/seniority'
import { teamNameColumn } from '@src/constants/columns/team'
import { lineManagerColumn } from '@src/constants/columns/employee'

const ROW: RowInterface<RequisitionInterface> = {
  cells: [
    {
      ...createRequisitionTitleColumn(false),
      title: 'Requisitions',
      width: 140,
    },
    {
      ...seniorityNameRequisitionsColumn,
      width: 140,
    },
    {
      ...teamNameColumn,
      width: 130,
    },
    {
      ...lineManagerColumn,
      title: 'Hiring manager',
      width: 130,
    },
    {
      ...requisitionHeadcountColumn,
      width: 130,
      title: 'Headcount',
    },
  ],
}

interface RequisitionTableProps {
  requisitions?: RequisitionInterface[]
  loading: boolean
}

export const RequisitionTable = ({ requisitions, loading }: RequisitionTableProps) => {
  if (!requisitions?.length) {
    return null
  }
  return (
    <Box py="s-16">
      <Widget>
        <Box p="s-16">
          <Text color={Token.color.greyTone50} variant="emphasis2">
            Connected requisitions
          </Text>
        </Box>
        <AdjustableTable
          count={requisitions.length}
          data={requisitions}
          dataType="Requisitions"
          hideCount
          loading={loading}
          name={TableNames.JobPostingRequisitions}
          row={ROW}
          useWindowScroll
        />
      </Widget>
    </Box>
  )
}
