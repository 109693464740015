import { FreeBusyCalendar, MeetingEvent, TimeSlot } from '@src/interfaces/meetings'
import { LargeWeeklyCalendarEventInterface } from '@components/LargeWeeklyCalendar'
import {
  baseCalendarEventBackgroundColor,
  calendarEventTextColor,
  highlightedEventBackgroundColor,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ScheduleOneToOne/constants'

export const adaptOneToOneMeetingToCalendarEvent = (
  event: MeetingEvent,
  selectedEventId?: number,
): LargeWeeklyCalendarEventInterface => ({
  id: event.id.toString(),
  title: event.title,
  start: event.start,
  end: event.end,
  color: calendarEventTextColor,
  backgroundColor:
    selectedEventId === event.id
      ? highlightedEventBackgroundColor
      : baseCalendarEventBackgroundColor,
  isReadOnly: true,
})

export const adaptFreeBusySlotsToCalendarEvents = (
  calendar: FreeBusyCalendar,
  excludeSlots: TimeSlot[],
): LargeWeeklyCalendarEventInterface[] =>
  Object.values(calendar.calendars).reduce<LargeWeeklyCalendarEventInterface[]>(
    (events, busySlot) => [
      ...events,
      ...busySlot.busy
        .filter(
          ({ start, end }) =>
            !excludeSlots.some(slot => slot.start === start && slot.end === end),
        )
        .map(timeSlot => ({
          ...timeSlot,
          color: calendarEventTextColor,
          backgroundColor: baseCalendarEventBackgroundColor,
          raw: {
            hideTime: true,
          },
          isReadOnly: true,
        })),
    ],
    [],
  )
