import { ScheduleOneToOneMeetingPayload } from '@src/api/meetings'
import { formatRRule } from '@src/utils/format'
import { format } from 'date-fns'

export const formatRecurrence = (event: ScheduleOneToOneMeetingPayload): string =>
  formatRRule(event.recurrence[0])

export const formatTimeDiff = (
  event: ScheduleOneToOneMeetingPayload,
  fullDayName: boolean = false,
): string => {
  const startDate = new Date(event.start)
  const endDate = new Date(event.end)

  const day = format(startDate, fullDayName ? 'eeee' : 'eee')
  const fromTime = format(startDate, 'HH:mm')
  const toTime = format(endDate, 'HH:mm')

  return `${day}, ${fromTime} - ${toTime}`
}
