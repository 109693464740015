import React from 'react'
import { Token } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTableReturnType } from '@src/components/TableV2/hooks'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { GetRequestData } from '@src/interfaces'
import { TableNames } from '@src/constants/table'
import { ColumnInterface, RowInterface, Stats } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import {
  importStateColumn,
  createImportActionsColumn,
} from '@src/constants/columns/bulkDataImport'
import { CustomFieldsForSectionInterface } from '@src/interfaces/customFields'
import { ImportEmployeeInterface } from '@src/constants/columns/importEmployees'
import Table from '@components/TableV2/Table'

type SessionTableRowConfig<T> = {
  createExtraFieldColumn: (field: string) => ColumnInterface<ImportInterface<T>>
  createCustomFieldColumn?: (
    field: string,
    customFields: CustomFieldsForSectionInterface[],
  ) => ColumnInterface<ImportEmployeeInterface>
  extraFields: string[]
  row: Partial<RowInterface<ImportInterface<T>>>
  sessionId: number
  onEdit: (id: number) => void
  onDelete: (sessionId: number, dataId: number) => AxiosPromise<any>
  onDeleteSuccess: () => void
  isLoadingCustomFields?: boolean
  customFields?: CustomFieldsForSectionInterface[]
}

type SessionTableProps<T> = {
  disableActionColumn: boolean
  disableStateColumn: boolean
  hiddenCells: Partial<Record<keyof T, boolean>>
  sessionRow: SessionTableRowConfig<T>
  table: useTableReturnType<ImportInterface<T>, Stats, GetRequestData<ImportInterface<T>>>
  tableName: TableNames
  onRowClick: (value: ImportInterface<T>) => void
}

export const SessionTable = <T,>({
  disableActionColumn,
  disableStateColumn,
  hiddenCells,
  sessionRow,
  table,
  tableName,
  onRowClick,
  ...props
}: SessionTableProps<T>) => {
  const sessionRowContent = sessionRow.row
  const importActionColumn = createImportActionsColumn<T>(
    sessionRow.sessionId,
    sessionRow.onEdit,
    sessionRow.onDelete,
    sessionRow.onDeleteSuccess,
  )
  const row = {
    ...sessionRowContent,
    highlight: data => {
      return Object.keys(data.errors || {}).length ? Token.color.redActionBackground : ''
    },
    cells: [
      ...(sessionRowContent?.cells ?? []),
      ...(sessionRow.createCustomFieldColumn
        ? sessionRow.isLoadingCustomFields || !sessionRow.customFields
          ? []
          : sessionRow.extraFields.map(field => ({
              ...sessionRow.createCustomFieldColumn!(field, sessionRow.customFields!),
              width: 150,
            }))
        : sessionRow.extraFields.map(field => ({
            ...sessionRow.createExtraFieldColumn(field),
            width: 150,
          }))),
      {
        ...importActionColumn,
        width: 150,
      },
      {
        ...importStateColumn,
        width: 150,
      },
    ],
  } as RowInterface<ImportInterface<T>>

  return (
    <PageBody maxWidth="100%">
      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable
            {...props}
            {...table}
            lockFirstColumn={false}
            lockLastColumn
            name={tableName}
            row={row}
            rowHeight="medium"
            useWindowScroll
            hideCountAndButtonSection
            onRowClick={r => onRowClick(r)}
            hiddenCells={{
              ...hiddenCells,
              [importActionColumn.idPoint]: disableActionColumn,
              [importStateColumn.idPoint]: disableStateColumn,
            }}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </PageBody>
  )
}
