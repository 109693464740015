import { DualAvatar, Status } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'

import React, { useState } from 'react'
import { HeaderActions } from './HeaderActions'
import { PageBody } from '@src/components/Page/PageBody'
import { TwoColumnsLayout } from '../../../common/TwoColumnsLayout'
import { MeetingsList } from './MeetingsList'
import { MeetingItem } from './interfaces'

export const OneToOneSummary = () => {
  const [selectedMeeting, setSelectedMeeting] = useState<MeetingItem | undefined>()
  return (
    <PageWrapper>
      {/* connect api */}
      <PageHeader
        title={
          <PageHeader.Title
            avatar={
              <DualAvatar
                topImage="https://placedog.net/40/40?id=6"
                bottomImage="https://placedog.net/40/40?id=7"
              />
            }
            labels={<Status useIcon="Calendar">Friday, 10:30</Status>}
            title="1:1 Wade <> Jane"
            actions={<HeaderActions />}
          />
        }
        backUrl={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.OVERVIEW}
      />
      <PageBody>
        <TwoColumnsLayout
          leftFlex={0}
          leftMinWidth={270}
          left={
            <MeetingsList
              selectedMeeting={selectedMeeting}
              setSelectedMeeting={setSelectedMeeting}
            />
          }
          right={<></>}
        />
      </PageBody>
    </PageWrapper>
  )
}
