import { useLapeContext } from '@src/features/Form/LapeForm'
import { useFormObserver } from './Widgets/FormObserverProvider'
import { GoalContentType, GoalsInterface } from '@src/interfaces/goals'
import { useSubmitFlowHelpers } from '../common/utils'
import { goalsRequests } from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { isGenericNewGoalPath } from '../helpers'
import { useQuery } from '@src/utils/queryParamsHooks'
import { cleanMetricCache, useGoalFormCache } from '../useGoalFormCache'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { AxiosError } from 'axios'

const getModelContentType = (type: EntityTypes) => {
  switch (type) {
    case EntityTypes.department:
      return 'department'
    case EntityTypes.teams:
    case EntityTypes.team:
    default:
      return 'teams'
  }
}

export const useGoalFormSubmit = () => {
  const goalForm = useLapeContext<
    GoalsInterface & { targets: unknown; is_submit?: boolean }
  >()
  const { data: contentTypes } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )
  const isGenericNewGoal = isGenericNewGoalPath()
  const { query } = useQuery<{ type: EntityTypes }>()
  const { values: goalFormValues, submit: goalFormSubmit } = goalForm
  const { getFormsGetters, unregisterForm } = useFormObserver()
  const { cleanCache } = useGoalFormCache()
  const { showLoading, showError } = useSubmitFlowHelpers()

  // eslint-disable-next-line consistent-return
  const doSubmit = async (updateType: 'submit' | 'draft') => {
    const targetFormsGetters = getFormsGetters()
    let targetsError: unknown = false

    const hideLoading = showLoading('Saving...')

    if (goalFormValues.update_type?.id === 'target_based' && targetFormsGetters.length) {
      /*
          we need sequence of requests because BE blocks DB per request 
          error example: 
              deadlock detected
              DETAIL:  Process 2843751 waits for ShareLock on transaction 1046283; blocked by process 2843674.
              Process 2843674 waits for ShareLock on transaction 1046282; blocked by process 2843751.
              HINT:  See server log for query details.
              CONTEXT:  while updating tuple (115,42) in relation "kpi_target"
          */
      for (const getter of targetFormsGetters) {
        const { form: metricForm, validator } = getter()
        const formId = metricForm.values.id || metricForm.values.tempId

        if (!metricForm.dirty && !metricForm.values.tempId) {
          // eslint-disable-next-line no-continue
          continue
        }

        metricForm.apiErrors = {}
        const cb = validator?.validate
          ? // eslint-disable-next-line no-loop-func
            validator.validate(async () => {
              try {
                // Required while adding goal via performance. At this stage doesn't know what type of goal is it, and it's required before we update the KPIs (the form.submit()) that are required for the form submittion (submit())
                if (isGenericNewGoal) {
                  if (
                    [
                      EntityTypes.department,
                      EntityTypes.teams,
                      EntityTypes.team,
                    ].includes(query.type)
                  ) {
                    const contentType = contentTypes?.find(
                      ({ model }) => model === getModelContentType(query.type),
                    )
                    if (!goalFormValues.content_object?.id || !contentType) {
                      throw new Error('ORG_UNIT_MISSING')
                    }
                    if (query.type === EntityTypes.department) {
                      await goalsRequests.update(
                        {
                          object_id: goalFormValues.content_object.id,
                          content_object: goalFormValues.content_object,
                          content_type: contentType,
                          name: goalFormValues.name || undefined,
                        },
                        { id: String(goalFormValues.id) },
                      )
                      metricForm.values.department = goalFormValues.content_object
                      metricForm.values.is_company = goalFormValues.is_company
                    }
                    if (
                      query.type === EntityTypes.teams ||
                      query.type === EntityTypes.team
                    ) {
                      await goalsRequests.update(
                        {
                          object_id: goalFormValues.content_object.id,
                          content_object: goalFormValues.content_object,
                          content_type: contentType,
                          name: goalFormValues.name || undefined,
                        },
                        { id: String(goalFormValues.id) },
                      )
                      metricForm.values.team = goalFormValues.content_object
                      metricForm.values.is_company = goalFormValues.is_company
                    }
                  } else if (goalFormValues.is_company) {
                    await goalsRequests.update(
                      {
                        is_company: goalFormValues.is_company,
                        name: goalFormValues.name || undefined,
                        parent: goalFormValues.parent,
                      },
                      { id: String(goalFormValues.id) },
                    )
                    metricForm.values.is_company = goalFormValues.is_company
                  } else {
                    throw new Error('ORG_UNIT_MISSING')
                  }
                }

                // if we're saving a draft and there is no basic metric data (name and update type)
                // do not send kpi request so that we can save a goal with just a name
                if (
                  updateType === 'draft' &&
                  !metricForm.values.name &&
                  !metricForm.values.update_type
                ) {
                  formId && unregisterForm(formId)
                  // clean metric cache with temp id
                  formId && cleanMetricCache(formId)
                  return null
                }
                const result = await metricForm.submit()
                const goalTarget = {
                  ...result,
                  status: { id: result.status, name: result.status },
                }
                formId && unregisterForm(formId)
                // clean metric cache with temp id
                formId && cleanMetricCache(formId)
                goalFormValues.kpis = goalFormValues.kpis.map(kpi =>
                  kpi.tempId === formId ? goalTarget : kpi,
                )
                return goalTarget
              } catch (err) {
                if (
                  err?.response?.data?.object_id ||
                  err?.message === 'ORG_UNIT_MISSING'
                ) {
                  goalForm.apiErrors.object_id =
                    err?.response?.data?.object_id?.[0] ||
                    'Organisational unit cannot be empty'
                } else if (err?.response?.data?.department?.id) {
                  goalForm.apiErrors.object_id = err?.response?.data?.department?.id
                } else if (err?.response?.data?.team?.id) {
                  goalForm.apiErrors.object_id = err?.response?.data?.team?.id
                }
                targetsError = err
                // need to return the value not throw to not show error toast
                return err
              }
            })
          : metricForm.submit

        // eslint-disable-next-line no-await-in-loop
        await cb()
      }
    }

    if (!targetsError) {
      goalForm.apiErrors = {}

      if (updateType === 'submit') {
        goalFormValues.is_submit = true
      }
      if (updateType === 'draft') {
        goalFormValues.approval_status = {
          id: ApprovalStatuses.Draft,
          name: ApprovalStatuses.Draft,
        }
      }

      if (!goalFormValues.name) {
        // only empty string will trigger BE validation error in case of empty
        goalFormValues.name = ''
      }

      try {
        const result = await goalFormSubmit()
        cleanCache()
        // clean metric cache that do not have temp ids
        for (const metric of result.kpis) {
          cleanMetricCache(metric.id)
        }
        hideLoading()
        return result
      } catch (err) {
        if (err?.response.status === 403 || err?.response?.data?.detail) {
          hideLoading()
          showError('Failed to save goal', getStringMessageFromError(err))
        }
      }
    } else {
      if (
        (targetsError as AxiosError).response?.data?.detail ||
        (targetsError as AxiosError).response?.status === 403
      ) {
        hideLoading()
        showError(
          'Failed to save metric',
          getStringMessageFromError(targetsError as AxiosError),
        )
      } else {
        hideLoading()
      }

      // eslint-disable-next-line no-throw-literal
      throw targetsError
    }
  }

  return { submit: doSubmit }
}
