import React from 'react'
import SideBar from '@src/components/SideBar/SideBar'
import Form from '@src/features/Form/Form'
import { requisitionsRequestsNew } from '@src/api/requisitions'
import General from '@src/pages/Forms/RequisitionForm/General/General'
import { RequisitionInterface } from '@src/interfaces/requisitions'

interface RequisitionFormProps {
  onCreateNewRequisition: (requisition: RequisitionInterface) => void
}

const RequisitionForm = ({ onCreateNewRequisition }: RequisitionFormProps) => {
  return (
    <Form api={requisitionsRequestsNew}>
      <General isSidebar onSuccess={onCreateNewRequisition} />
    </Form>
  )
}

interface CreateNewRequisitionSidebarProps {
  onClose: VoidFunction
  onCreateNewRequisition: (requisition: RequisitionInterface) => void
}

export const CreateNewRequisitionSidebar = ({
  onClose,
  onCreateNewRequisition,
}: CreateNewRequisitionSidebarProps) => {
  return (
    <SideBar isOpen title="Create requisition" onClose={onClose}>
      <RequisitionForm onCreateNewRequisition={onCreateNewRequisition} />
    </SideBar>
  )
}
