import React from 'react'
import { MeetingTemplateInterface } from '@src/interfaces/meetings'
import { ActionButton, chain, Group, ItemSkeleton, VStack, Widget } from '@revolut/ui-kit'
import { PreviewSectionHeader } from './PreviewSectionHeader'
import pluralize from 'pluralize'
import { MeetingTemplateEditStep } from '../interfaces'

interface Props {
  data?: MeetingTemplateInterface
  isLoading: boolean
  onEdit: (step: MeetingTemplateEditStep) => void
}

const Skeleton = () => {
  return (
    <Group p="s-16">
      <ItemSkeleton />
      <ItemSkeleton />
    </Group>
  )
}

export const PreviewAudienceSection = ({ data, isLoading, onEdit }: Props) => {
  if (isLoading) {
    return <Skeleton />
  }

  return (
    <Group p="s-16">
      <PreviewSectionHeader
        title={
          data?.audiences?.length ? chain('Audience', data?.audiences.length) : 'Audience'
        }
        subtitle="Who should this template apply to?"
        iconName="People"
        actionElement={
          <ActionButton onClick={() => onEdit('audience')}>Edit</ActionButton>
        }
      />
      <VStack pt="s-16" gap="s-8">
        {data?.audiences?.map(audience => (
          <Widget key={audience.id} p="s-8">
            <PreviewSectionHeader
              title={audience.name}
              subtitle={chain(
                audience.audience_type.name,
                `${pluralize('member', audience.headcount, true)}`,
              )}
              iconName="Bank"
            />
          </Widget>
        ))}
      </VStack>
    </Group>
  )
}
