import React, { useState } from 'react'
import { PageBody } from '@components/Page/PageBody'
import { Avatar, Item, Subheader, Text, VStack } from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { handleError } from '@src/api'
import axios from 'axios'
import { SecurityErrorPopup } from '@src/pages/Settings/Security/SecurityErrorPopup'
import { useDispatch, useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useQueryClient } from 'react-query'
import { API, Environments, env } from '@src/constants/api'
import { SecuritySettingsInterface } from '@src/interfaces/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { logOutAction } from '@src/store/auth/actions'

export const Security = () => {
  const { initialValues } = useLapeContext<SecuritySettingsInterface>()

  const [error, setError] = useState<string>()
  const [twoFactorTurnedOn, setTwoFactorTurnedOn] = useState(false)

  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const dispatch = useDispatch()

  const queryClient = useQueryClient()
  const canEdit = permissions.includes(PermissionTypes.ChangeSecuritySettings)
  const canSeeLoginWithOkta = featureFlags?.includes(FeatureFlags.OktaIntegration)
  const canSeeLoginWithEmailAndPassword = !featureFlags?.includes(
    FeatureFlags.CommercialProduct,
  )
  const canSeeLoginWithGoogle = env !== Environments.productionCommercial
  const canSeeAnyLoginMethod =
    canSeeLoginWithOkta || canSeeLoginWithEmailAndPassword || canSeeLoginWithGoogle

  const onError = (e: Error) => {
    if (!axios.isAxiosError(e)) {
      throw e
    }
    if (e?.response?.data?.non_field_error?.[0]) {
      setError(e?.response?.data?.non_field_error?.[0])
      return
    }
    handleError(e)
  }

  return (
    <>
      <PageBody>
        {canSeeAnyLoginMethod && (
          <>
            <Subheader>Login Options</Subheader>
            <Text>Choose which tool do you allow your employees to login</Text>

            <VStack mt="s-12" mb="s-16" space="s-16">
              {canSeeLoginWithEmailAndPassword && (
                <Item use="label">
                  <Item.Avatar>
                    <Avatar useIcon="ChangePasscode" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>Enable login with email and password</Item.Title>
                    <Item.Description>
                      Allow all your employees to log in with their work email and
                      password
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <LapeNewSwitch
                      name="enable_password_authentication"
                      disabled={!canEdit}
                    />
                  </Item.Side>
                </Item>
              )}

              {canSeeLoginWithGoogle && (
                <Item use="label">
                  <Item.Avatar>
                    <Avatar useIcon="LogoGoogle|image" bg="white" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>Enable login with Google</Item.Title>
                    <Item.Description>
                      Allow all your employees to log in with their Google Workspace
                      account
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <LapeNewSwitch name="enable_google_sso" disabled={!canEdit} />
                  </Item.Side>
                </Item>
              )}

              {canSeeLoginWithOkta && (
                <Item use="label">
                  <Item.Avatar>
                    <Avatar
                      image="https://assets.revolut.com/assets/stocks/OKTA.svg"
                      variant="brand"
                    />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>Enable login with Okta</Item.Title>
                    <Item.Description>
                      Allow all your employees to log in with their Okta account
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <LapeNewSwitch name="enable_okta_sso" disabled={!canEdit} />
                  </Item.Side>
                </Item>
              )}
            </VStack>
          </>
        )}

        <Subheader>2-factor authentification</Subheader>

        <Item use="label">
          <Item.Avatar>
            <Avatar useIcon="Logo2Fa" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Activate 2-factor authentication</Item.Title>
            <Item.Description>
              Your employees will be asked to create a 2-Step Login via SMS messages.
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <LapeNewSwitch
              name="enable_two_factor_authentication"
              disabled={!canEdit}
              onAfterChange={enableTwoFA => {
                setTwoFactorTurnedOn(
                  !initialValues.enable_two_factor_authentication && enableTwoFA,
                )
              }}
            />
          </Item.Side>
        </Item>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<SecuritySettingsInterface>
          successText="Settings saved successfully"
          onSubmitError={onError}
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.LOGIN_SETTINGS)
            if (twoFactorTurnedOn) {
              dispatch(logOutAction())
            }
          }}
          confirmationDialogue={
            twoFactorTurnedOn
              ? {
                  label: 'Enabling 2-Factor authentication',
                  body: 'Enabling 2 factor authentication for the first time will require you to re-login. Do you want to proceed?',
                  yesMessage: 'Confirm',
                  noMessage: 'Cancel',
                  variant: 'compact',
                }
              : undefined
          }
        />
      </PageActions>

      <SecurityErrorPopup error={error} onClose={() => setError(undefined)} />
    </>
  )
}
